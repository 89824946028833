import React from 'react'
import { Stepper, Step, Button } from "@material-tailwind/react";

import TemplatesPreview from '../templates/TemplatePreview';
import TemplateSizes from '../templates/TemplateSizes'

import { useState } from 'react';

const TemplateStepper = ({ category }) => {

    const [activeStep, setActiveStep] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);

    const [formData, setFormData] = useState({
        sizePage: '',
        typePage: '',
    })

    const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

    const renderStepper = () => {
        if (activeStep === 0) { return <TemplateSizes formData={formData} setFormData={setFormData} /> }
        if (activeStep === 1) { return <TemplatesPreview category={'catalog'} size={formData.sizePage} type={formData.typePage} /> }
    }

    return (
        <div class="container my-4 mx-auto px-4 md:px-4" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '670px'
        }}>
            <div class="flex flex-wrap ">
                {renderStepper()}
            </div>

            <div className="flex justify-between">
                {activeStep === 0 ? <div></div> : (
                    <Button onClick={handlePrev} disabled={isFirstStep} className='capitalize float-left'>
                        Προηγούμενο
                    </Button>
                )}
                {activeStep === 1 ? <div></div> : (
                    <Button onClick={handleNext} disabled={isLastStep} className='capitalize float-right'>
                        Επόμενο
                    </Button>
                )}
            </div>
        </div>
    )
}

export default TemplateStepper