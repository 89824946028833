import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  IconButton
} from "@material-tailwind/react";

import logo from './../../../assets/logo.png'

import { IoBagOutline } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
import { LuUser } from "react-icons/lu";
import { IoPieChartOutline } from "react-icons/io5";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const menuItems = [
  {
    title: "Παραγγελίες",
    path: "orders",
    icon: <IoBagOutline className="h-5 w-5" />
  },
  {
    title: "Πωλητές",
    path: "users",
    icon: <LuUsers className="h-5 w-5" />
  },
  {
    title: "Προφίλ",
    path: "profile",
    icon: <LuUser className="h-5 w-5" />
  },
  {
    title: "Report",
    path: "report",
    icon: <IoPieChartOutline className="h-5 w-5" />
  }
]

const Sidebar = () => {

  const navigate = useNavigate()

  return (
    <Card className="h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <div className="flex justify-start items-center mb-2 p-4">
        <IconButton onClick={() => navigate('/home')}>
          <MdOutlineArrowBackIosNew className="h-4 w-4" />
        </IconButton>
        <div className="text-lg font-semibold ml-10">
          <img src={logo} alt="img" className="object-cover h-12" />
        </div>
      </div>

      <List>
        {menuItems.map((item) => (
          <Link key={item.title} to={item.path}>
            <ListItem>
              <ListItemPrefix>
                {item.icon}
              </ListItemPrefix>
              {item.title}
            </ListItem>
          </Link>
        ))}
      </List>
    </Card>
  )
}

export default React.memo(Sidebar)