import React from 'react'
import { useSelector } from 'react-redux';

const BrandLogoPreview = ({ styles }) => {

  const brandLogo = useSelector((state) => state.page.brandLogo)

  return (
    <>
      {brandLogo ? (
        <img
          src={brandLogo}
          style={{
            width: `${styles.logoWidth}`,
            top: `${styles.logoTop}`,
            bottom: `${styles.logoBottom}`,
            left: `${styles.logoLeft}`,
            right: `${styles.logoRight}`,
            margin: `${styles.logoMargin}`,
            border: 'none',
            position: 'absolute'
          }}
          alt="logo"
        />
      ) : (
        <div style={{
          width: `${styles.logoWidth}`,
          minHeight: '30px',
          top: `${styles.logoTop}`,
          bottom: `${styles.logoBottom}`,
          left: `${styles.logoLeft}`,
          right: `${styles.logoRight}`,
          margin: `${styles.logoMargin}`,
          border: '1px dashed rgb(156 163 175)',
          position: 'absolute'
        }} />
      )}
    </>


  )
}

export default BrandLogoPreview