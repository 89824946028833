import React, { useState } from 'react'
import { Button, Dialog, DialogBody, DialogFooter, Input } from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { addTemplateMenuPrice, removeTemplateMenu } from './../../../../lib/features/template/templateSlice';

import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineDrag } from "react-icons/ai";

const CoctailsPrice = ({ item }) => {

    const dispatch = useDispatch()

    const page = useSelector((state) => state.page.current)

    const [open, setOpen] = useState(false);
    const [id, setId] = useState('')

    const handleOpen = (id) => {
        setId(id)
        setOpen(!open);
    }

    const onHandleChange = (e) => {
        dispatch(addTemplateMenuPrice({ id: item.id, price: e.target.value, page: page }))
    }

    const handleDeleteItem = () => {
        dispatch(removeTemplateMenu({ id: id, page: page }))
    }

    return (
        <>
            <div className="flex items-center w-100 justify-start gap-x-6 py-2 border-2 border-dashed border-gray-400 p-4 mt-5 mb-14 relative bg-white">
                <div className="" style={{ background: '#212121', position: 'absolute', top: '-32px', left: '-2px', padding: '8px' }}>
                    <AiOutlineDrag className="h-4 w-4 text-white" />
                </div>
                <div className='flex flex-row gap-x-2'>
                    <div className="bg-red-500 py-3 px-3 rounded-md cursor-default"
                        onClick={() => handleOpen(item.id)}>
                        <FaRegTrashAlt className="h-3 w-3 text-white" />
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <div className="text-md font-semibold mb-1">{item.valueEng}</div>
                    <div className="text-xs text-gray-600">{item.descriptionEng}</div>
                </div>
                <div className='w-20 overflow-hidden right'>
                    <Input variant="standard" type="number" placeholder=" €" value={item.price} onChange={onHandleChange} size="md" style={{ fontSize: '20px' }} />
                </div>
            </div>


            <Dialog open={open} handler={handleOpen} size='xs' className="p-4">
                <DialogBody>
                    <div className="text-xl mb-2">
                        Είστε σίγουροι ότι θέλετε να διαγράψετε
                    </div>
                </DialogBody>
                <DialogFooter className="gap-x-4 justify-center">
                    <Button onClick={handleOpen} variant="outlined">
                        <span className="capitalize font-normal">Άκυρο</span>
                    </Button>
                    <Button onClick={() => handleDeleteItem(id)}>
                        <span className="capitalize font-normal">Διαγραφή</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default CoctailsPrice