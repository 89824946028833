import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { AnimatePresence } from "framer-motion";

import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates } from './lib/features/template/templateSlice';
import { fetchMe } from './lib/features/user/userSlice';

import ProtectedRoute from './context/protectRoute'
import Login from './pages/Login'
import Register from './pages/Register'
import Template from './pages/Template'
import Home from './pages/Home'

import Dashboard from './pages/Dashboard/'
import Orders from './pages/Dashboard/orders';
import Users from './pages/Dashboard/users';
import Profile from './pages/Dashboard/profile';
import Reports from './pages/Dashboard/report';

const App = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchMe());
  }, []);

  useEffect(() => {
    dispatch(fetchTemplates())
  }, [])

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path='/' element={<Login />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='home' element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
        <Route path='templates/:id' element={
          <ProtectedRoute>
            <Template />
          </ProtectedRoute>
        } />


        <Route path='dashboard' element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }>

          <Route path='orders' element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          } />
          <Route path='users' element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          } />
          <Route path='profile' element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } />
          <Route path='report' element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          } />

        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default App