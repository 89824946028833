import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setZoom } from '../../../../lib/features/layout/zoomSlice';

import priceOld from '../../../../assets/products/ELEMENT1.png'
import logos from '../../../../assets/products/logos.png'

const ComboPages = ({ state, data }) => {

    const template = useSelector((state) => state.template.template)
    const page = useSelector((state) => state.page.current)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setZoom(120))
    }, [])

    return (
        <>
            {template.pages[page].map((obj, idx) => (
                // <div
                //     key={idx}
                //     className="relative shadoww-xl"
                //     style={{
                //         width: `${template.currentSize.w}px`,
                //         height: `${template.currentSize.h}px`,
                //         backgroundImage: `url(${obj.cover})`,
                //         position: 'relative'
                //     }}>

                //     <div>
                //         <div style={{
                //             position: 'absolute',
                //             top: `${obj.styles.descriptionTop}px`,
                //             left: `${obj.styles.descriptionLeft}px`,
                //         }}>
                //             <div
                //                 style={{
                //                     color: `${obj.styles.descriptionColor}`,
                //                     fontSize: `${obj.styles.descriptionFontSize}px`,
                //                     fontWeight: 'bold',
                //                     maxWidth: '240px'
                //                 }}>
                //                 {obj.description !== '' ? <div dangerouslySetInnerHTML={{ __html: `${obj.description}` }} /> : ''}
                //             </div>
                //         </div>
                //         <div
                //             style={{
                //                 position: 'absolute',
                //                 top: `${obj.styles.priceTop}px`,
                //                 left: `${obj.styles.priceLeft}px`
                //             }}>
                //             <div className='flex gap-x-1 items-center px-3'>
                //                 <div
                //                     style={{
                //                         color: `${obj.styles.priceColor}`,
                //                         fontSize: `${obj.styles.priceFontSize}px`,
                //                         fontWeight: 'bold'
                //                     }}
                //                 >ΑΠΟ</div>
                //                 <div
                //                     style={{
                //                         backgroundRepeat: 'no-repeat',
                //                         padding: '0 5px',
                //                         backgroundSize: 'contain',
                //                         backgroundImage: `url(${priceOld})`,
                //                         color: `${obj.styles.priceColor}`,
                //                         fontSize: `${obj.styles.priceFontSize}px`,
                //                         fontWeight: 'bold'
                //                     }}>
                //                     {obj.price === '' ? 'Χ,ΧΧ€' : `${obj.price}€`}
                //                 </div>
                //             </div>
                //             <div style={{
                //                 width: '105px',
                //                 height: '50px',
                //                 backgroundRepeat: 'no-repeat',
                //                 backgroundSize: 'contain',
                //                 backgroundImage: `url('${obj.styles.decor}')`
                //             }}>
                //                 <div
                //                     className='text-white text-center py-1'
                //                     style={{ fontSize: '10px', color: `${obj.styles.priceOfferColor}` }}>
                //                     ΜΟΝΟ
                //                 </div>
                //                 <div
                //                     style={{
                //                         color: `${obj.styles.priceOfferColor}`,
                //                         fontSize: `${obj.styles.priceOfferFontSize}px`,
                //                         textAlign: 'center',
                //                         fontWeight: 'bold',
                //                         marginTop: '-12px'
                //                     }}>
                //                     {obj.priceOffer === '' ? 'Χ,ΧΧ€' : `${obj.priceOffer}€`}
                //                 </div>
                //             </div>
                //         </div>
                //     </div>

                //     <div className='relative'>
                //         <div>
                //             {obj.items.map((product, idx) => (
                //                 <div key={idx}>
                //                     {product.category === 'drink' ? (
                //                         <img src={product.img}
                //                             style={{
                //                                 position: 'absolute',
                //                                 top: `${obj.styles.product1Top}px`,
                //                                 right: `${obj.styles.product1Right}px`,
                //                                 maxWidth: `${obj.styles.product1MaxWidth}px`,
                //                                 zIndex: '1'
                //                             }}
                //                             alt="product" />) : ''}

                //                     {product.category === 'food' ? (
                //                         <img src={product.img}
                //                             style={{
                //                                 position: 'absolute',
                //                                 top: `${obj.styles.product2Top}px`,
                //                                 right: `${obj.styles.product2Right}px`,
                //                                 maxWidth: `${obj.styles.product2MaxWidth}px`,
                //                                 zIndex: '10'
                //                             }}
                //                             alt="product" />) : ''}
                //                 </div>
                //             ))}

                //         </div>
                //     </div>
                // </div >

                <div
                    key={idx}
                    className="relative shadoww-xl"
                    style={{
                        width: `${template.currentSize.w}px`,
                        height: `${template.currentSize.h}px`,
                        backgroundImage: `url(${obj.cover})`,
                        position: 'relative'
                    }}>
                    <div className='combo-holder'
                        style={{
                            position: 'absolute',
                            top: '240px',
                            left: '70px'
                        }}>
                        <div className='description'
                            style={{
                                color: `${obj.styles.descriptionColor}`,
                                fontSize: `${obj.styles.descriptionFontSize}px`,
                                fontFamily: `${obj.styles.descriptionFontFamily}`,
                                fontWeight: 'bold',
                                maxWidth: '240px',
                                width: '240px',
                                position: 'absolute',
                                left: `${obj.styles.descriptionLeft}`,
                                top: `${obj.styles.descriptionTop}`,
                                textAlign: `${obj.styles.descriptionTextAlign}`,
                            }}>
                            {obj.description !== '' ? <div dangerouslySetInnerHTML={{ __html: `${obj.description}` }} /> : `XXL HOT DOG \n+ COCA-COLA 500ml`}
                        </div>
                        <div className='old-price flex items-center gap-x-4'
                        style={{
                            left: `${obj.styles.priceLeft}`,
                            top: `${obj.styles.priceTop}`,
                            fontWeight: 'bold',
                            position: 'absolute'
                        }}>
                            <div style={{
                                color: `${obj.styles.priceColor}`,
                                fontSize: `${obj.styles.priceFontSize}px`,
                                fontFamily: `${obj.styles.descriptionFontFamily}`,
                                fontWeight: 'bold',
                            }}>ΑΠΟ</div>
                            <div className='from-price relative'>{obj.price === '' ? 'Χ,ΧΧ€' : `${obj.price}€`}</div>
                        </div>
                        <div className='offer-price'
                        style={{
                            position: 'absolute',
                            top: '43px',
                            top: `${obj.styles.priceOfferTop}`,
                            fontFamily: `${obj.styles.priceOfferFontFamily}`,
                            left: `${obj.styles.priceOfferLeft}`,
                            color: `${obj.styles.priceOfferColor}`,
                            fontSize: `${obj.styles.priceOfferFontSize}px`,
                            lineHeight: '1',
                            textAlign: 'center'
                        }}>
                            <div
                                style={{
                                    fontSize: '10px',
                                    color: `${obj.styles.priceOfferColor}`
                                }}>ΜΟΝΟ</div>
                            {obj.priceOffer === '' ? 'Χ,ΧΧ€' : `${obj.priceOffer}€`}
                        </div>
                    </div>

                    <div className='relative'>
                        <div>
                            {obj.items.map((product, idx) => (
                                <div key={idx}>
                                    {product.category === 'drink' ? (
                                        <img src={product.img}
                                            style={{
                                                position: 'absolute',
                                                top: `${obj.styles.product1Top}px`,
                                                right: `${obj.styles.product1Right}px`,
                                                maxWidth: `${obj.styles.product1MaxWidth}px`,
                                                zIndex: '1'
                                            }}
                                            alt="product" />) : ''}

                                    {product.category === 'food' ? (
                                        <img src={product.img}
                                            style={{
                                                position: 'absolute',
                                                top: `${obj.styles.product2Top}px`,
                                                right: `${obj.styles.product2Right}px`,
                                                maxWidth: `${obj.styles.product2MaxWidth}px`,
                                                zIndex: '10'
                                            }}
                                            alt="product" />) : ''}
                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            ))}
        </>
    )
}

export default ComboPages