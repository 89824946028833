import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setZoom } from '../../../../lib/features/layout/zoomSlice';

import priceOld from '../../../../assets/products/ELEMENT1.png'
import logos from '../../../../assets/products/logos.png'

import ScrollDown from './../../../Layout/scrollDown'

const ComboPages = () => {

    const dispatch = useDispatch();

    const template = useSelector((state) => state.template.template)

    useEffect(() => {
        dispatch(setZoom(80))
    }, [])

    return (
        <>
            <div id="divToPrint">
                {template.pages.map((page, idx) => (
                    <div style={{ padding: '10px' }}>
                        {
                            (
                                page.map((obj, idx) => (
                                    <div
                                        key={idx}
                                        className="relative shadoww-xl"
                                        style={{
                                            width: `${template.currentSize.w}px`,
                                            height: `${template.currentSize.h}px`,
                                            backgroundImage: `url(${obj.cover})`,
                                            position: 'relative'
                                        }}>
                                        <div className='combo-holder'
                                            style={{
                                                position: 'absolute',
                                                top: '240px',
                                                left: '70px'
                                            }}>
                                            <div className='description'
                                                style={{
                                                    color: `${obj.styles.descriptionColor}`,
                                                    fontSize: `${obj.styles.descriptionFontSize}px`,
                                                    fontFamily: `${obj.styles.descriptionFontFamily}`,
                                                    fontWeight: 'bold',
                                                    maxWidth: '240px',
                                                    width: '240px',
                                                    position: 'absolute',
                                                    left: `${obj.styles.descriptionLeft}`,
                                                    top: `${obj.styles.descriptionTop}`,
                                                    textAlign: `${obj.styles.descriptionTextAlign}`,
                                                }}>
                                                {obj.description !== '' ? <div dangerouslySetInnerHTML={{ __html: `${obj.description}` }} /> : `XXL HOT DOG \n+ COCA-COLA 500ml`}
                                            </div>
                                            <div className='old-price flex items-center gap-x-4'
                                                style={{
                                                    left: `${obj.styles.priceLeft}`,
                                                    top: `${obj.styles.priceTop}`,
                                                    fontWeight: 'bold',
                                                    position: 'absolute'
                                                }}>
                                                <div style={{
                                                    color: `${obj.styles.priceColor}`,
                                                    fontSize: `${obj.styles.priceFontSize}px`,
                                                    fontFamily: `${obj.styles.descriptionFontFamily}`,
                                                    fontWeight: 'bold',
                                                }}>ΑΠΟ</div>
                                                <div className='from-price relative'>{obj.price === '' ? 'Χ,ΧΧ€' : `${obj.price}€`}</div>
                                            </div>
                                            <div className='offer-price'
                                                style={{
                                                    position: 'absolute',
                                                    top: '43px',
                                                    top: `${obj.styles.priceOfferTop}`,
                                                    fontFamily: `${obj.styles.priceOfferFontFamily}`,
                                                    left: `${obj.styles.priceOfferLeft}`,
                                                    color: `${obj.styles.priceOfferColor}`,
                                                    fontSize: `${obj.styles.priceOfferFontSize}px`,
                                                    lineHeight: '1',
                                                    textAlign: 'center'
                                                }}>
                                                <div
                                                    style={{
                                                        fontSize: '10px',
                                                        color: `${obj.styles.priceOfferColor}`
                                                    }}>ΜΟΝΟ</div>
                                                {obj.priceOffer === '' ? 'Χ,ΧΧ€' : `${obj.priceOffer}€`}
                                            </div>
                                        </div>

                                        <div className='relative'>
                                            <div>
                                                {obj.items.map((product, idx) => (
                                                    <div key={idx}>
                                                        {product.category === 'drink' ? (
                                                            <img src={product.img}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: `${obj.styles.product1Top}px`,
                                                                    right: `${obj.styles.product1Right}px`,
                                                                    maxWidth: `${obj.styles.product1MaxWidth}px`,
                                                                    zIndex: '1'
                                                                }}
                                                                alt="product" />) : ''}

                                                        {product.category === 'food' ? (
                                                            <img src={product.img}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: `${obj.styles.product2Top}px`,
                                                                    right: `${obj.styles.product2Right}px`,
                                                                    maxWidth: `${obj.styles.product2MaxWidth}px`,
                                                                    zIndex: '10'
                                                                }}
                                                                alt="product" />) : ''}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                ))}
            </div>
            {/* <ScrollDown /> */}
        </>
    )
}

export default ComboPages