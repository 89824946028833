import React from 'react'
import { useSelector } from 'react-redux';

const ClientLogoPreview = ({ styles }) => {

  const logo = useSelector((state) => state.page.logo)

  return (
    <>
      {logo ? (
        <img
          src={logo}
          style={{
            width: `${styles.logoWidth}`,
            height: `${styles.logoHeight}`,
            top: `${styles.logoTop}`,
            bottom: `${styles.logoBottom}`,
            left: `${styles.logoLeft}`,
            right: `${styles.logoRight}`,
            margin: `${styles.logoMargin}`,
            border: 'none',
            position: 'absolute'
          }}
          alt="logo"
        />
      ) : (
        <div style={{
          width: `${styles.logoWidth}`,
          height: `${styles.logoHeight}`,
          top: `${styles.logoTop}`,
          bottom: `${styles.logoBottom}`,
          left: `${styles.logoLeft}`,
          right: `${styles.logoRight}`,
          margin: `${styles.logoMargin}`,
          border: '1px dashed rgb(156 163 175)',
          position: 'absolute',
          minHeight: '50px'
        }} />
      )}
    </>


  )
}

export default ClientLogoPreview