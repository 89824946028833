import React, { useState } from 'react'
import { Link } from "react-router-dom";
import {
    Typography,
    Card,
    CardBody,
    Dialog,
    DialogBody,
    DialogFooter,
    Button
} from "@material-tailwind/react";

import { BiSolidDrink } from "react-icons/bi";
import { FaRegListAlt } from "react-icons/fa";

import Templates from '../../../components/Home/templates';

const Cafebar = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const [category, setCategory] = useState('')

    const selectHandle = (category) => {
        setCategory(category)
        handleOpen()
    }

    return (
        <>
            <div className="grid grid-cols-3 grid-rows-1 gap-4 p-4">
                <Card className="mt-6 w-100" onClick={() => selectHandle('cocktail')}>
                    <div color="blue-gray" className="relative h-56 flex justify-center flex-col m-auto">
                        <BiSolidDrink className='text-9xl' />
                    </div>
                    <CardBody className='text-center'>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                            COCKTAIL LIST
                        </Typography>
                    </CardBody>
                </Card>

                <Link to="/home">
                    <Card className="mt-6 w-100">
                        <div color="blue-gray" className="relative h-56 flex justify-center flex-col m-auto">
                            <FaRegListAlt className='text-9xl' />
                        </div>
                        <CardBody className='text-center'>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                ΚΑΤΑΛΟΓΟΙ
                            </Typography>
                        </CardBody>
                    </Card>
                </Link>
            </div>

            <Dialog open={open} handler={handleOpen} size="xl" className='overflow-scroll max-h-[90vh]'>
                <DialogBody>
                    <Templates category={category} />
                </DialogBody>
            </Dialog>
        </>
    )
}

export default Cafebar