import React, { useState } from 'react'
import { Button, Dialog, DialogBody, DialogFooter, Textarea, Input } from "@material-tailwind/react";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { addTemplateMenu } from './../../../../lib/features/template/templateSlice';

import { v4 as uuidv4 } from 'uuid';
import CustomListItem from '../cutomList/customListItem';

import { BsTranslate } from "react-icons/bs";
import { AiOutlineClear } from "react-icons/ai";

const CustomList = () => {

    const dispatch = useDispatch()
    const items = useSelector((state) => state.menu.items)
    const page = useSelector((state) => state.page.current)

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const [titleValue, setTitleValue] = useState('')
    const [descValue, setDescValue] = useState('')

    const [outputTextTitle, setOutputTextTitle] = useState('');
    const [outputTextDescription, setOutputTextDescription] = useState('');

    const translate = async () => {
        const options = {
            method: 'POST',
            url: 'https://microsoft-translator-text.p.rapidapi.com/translate',
            params: {
                'to[0]': 'en',
                'api-version': '3.0',
                profanityAction: 'NoAction',
                textType: 'plain'
            },
            headers: {
                'content-type': 'application/json',
                'X-RapidAPI-Key': '195b500c54mshacd3aa12602da9ep150c6bjsn9c70a9d44c59',
                'X-RapidAPI-Host': 'microsoft-translator-text.p.rapidapi.com'
            },
            data: [
                {
                    Text: `${titleValue}`
                },
                {
                    Text: `${descValue}`
                }
            ]
        };

        try {
            const response = await axios.request(options)
            const translatedTextTitle = response.data[0].translations[0].text
            const translatedTextDescription = response.data[1].translations[0].text
            setOutputTextTitle(translatedTextTitle)
            setOutputTextDescription(translatedTextDescription)
        } catch (error) {
            console.error(error)
        }
    }

    const handleClear = () => {
        setTitleValue('')
        setDescValue('')
        setOutputTextTitle('')
        setOutputTextDescription('')
    }

    const addMenuItemHandler = () => {
        const item = {
            id: uuidv4(),
            text: titleValue,
            label: titleValue,
            valueEng: titleValue,
            valueGr: outputTextTitle,
            price: '',
            descriptionEng: descValue,
            descriptionGr: outputTextDescription
        }
        const item2 = {
            id: uuidv4(),
            text: titleValue,
            label: titleValue,
            valueEng: outputTextTitle,
            valueGr: outputTextTitle,
            price: '',
            descriptionEng:  outputTextDescription,
            descriptionGr: outputTextDescription
        }

        dispatch(addTemplateMenu({ data: item, page: page }))
        dispatch(addTemplateMenu({ data: item2, page: page }))
        setTitleValue('')
        setDescValue('')
        setOpen(!open)
    }

    return (
        <>
            <div className='mb-10'>
                <Button onClick={handleOpen} className="normal-case w-full" size="md">
                    Προσθήκη δικιά σας συνταγής
                </Button>
            </div>

            {items?.map((item) => (
                <CustomListItem
                    key={item.id}
                    item={item}
                />
            ))}

            <Dialog open={open} handler={handleOpen} size='lg' className="p-8">
                <DialogBody>
                    <div className="mt-10 flex justify-center gap-x-5 items-center">
                        <div>
                            <div className="w-96 mb-4">
                                <Input label="Τίτλος"
                                    value={titleValue}
                                    onChange={(e) => setTitleValue(e.target.value)} />
                            </div>
                            <div className="w-96">
                                <Textarea label="Περιγραφή"
                                    value={descValue}
                                    onChange={(e) => setDescValue(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <Button className="flex items-center gap-3 bg-blue-500" onClick={translate}>
                                <BsTranslate className="h-5 w-5" />
                            </Button>
                            <Button className="flex items-center gap-3 bg-red-500" onClick={handleClear}>
                                <AiOutlineClear className="h-5 w-5 text-white" />
                            </Button>
                        </div>
                        <div>
                            <div className="w-96 mb-4">
                                <Input label="μεταφραση αγγλικα" value={outputTextTitle || ''} onChange={() => console.log('')} />
                            </div>
                            <div className="w-96">
                                <Textarea label="μεταφραση αγγλικα" value={outputTextDescription || ''} onChange={() => console.log('')} />
                            </div>
                        </div>
                    </div>
                </DialogBody >
                <DialogFooter className="flex justify-center gap-x-6">
                    <Button onClick={handleOpen} className='mt-10' size='lg' variant="outlined">
                        <span className='capitalize'>Ακύρωση</span>
                    </Button>
                    <Button
                        disabled={(titleValue !== '') ? false : true}
                        onClick={addMenuItemHandler} className='mt-10' size='lg'>
                        <span className='capitalize'>Προσθήκη</span>
                    </Button>
                </DialogFooter>
            </Dialog >
        </>
    )
}

export default CustomList