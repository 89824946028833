import React, { useEffect } from 'react'

import CocktailsList from './../../menu/cocktailsList/'
import CatalogList from '../../menu/catalogList';
import CustomList from '../../menu/cutomList';

import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";

import { useDispatch } from 'react-redux'
import { resetSignature } from '../../../../lib/features/menu/menuSlice';


const MenuList = () => {
    const data = [
        {
            label: "Cocktails",
            value: "cocktails",
            desc: <CocktailsList />,
        },
        {
            label: "Menu",
            value: "menu",
            desc: <CatalogList />,
        },
        {
            label: "Custom",
            value: "custom",
            desc: <CustomList />,
        },
    ];

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetSignature(resetSignature))
    }, [])

    return (
        <>
            <div className="text-2xl font-semibold py-2">Επιλέξτε συνταγές</div>
            <div className="w-100 flex gap-x-4 mb-10 mt-5">
                <div className="relative w-full min-w-[200px] h-10 ">
                    <Tabs value="cocktails">
                        <TabsHeader>
                            {data.map(({ label, value }) => (
                                <Tab key={value} value={value}>
                                    {label}
                                </Tab>
                            ))}
                        </TabsHeader>
                        <TabsBody
                            style={{ overflow: 'scroll', padding: '0', height: '100vh' }}
                            animate={{
                                initial: { y: 250 },
                                mount: { y: 0 },
                                unmount: { y: 250 },
                            }}>
                            {data.map(({ value, desc }) => (
                                <TabPanel key={value} value={value} style={{ padding: '0', marginTop: '20px' }}>
                                    {desc}
                                </TabPanel>
                            ))}
                        </TabsBody>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default MenuList