import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { decremented, incremented, setZoom } from '../../../lib/features/layout/zoomSlice.js';
import { totalPages, disableZoom } from "../../../lib/features/layout/page.js";

import Page from "./cocktailsPages/";
import ComboPages from "./comboPages/";
import CatalogPages from "./catalogPages/";

import { fetchTemplate } from "../../../lib/features/template/templateSlice.js";
import Loader from "../../Layout/loader/index.jsx";
import { motion } from "framer-motion";
import Pages from "./pages/index.jsx";

import PanZoom, { Element } from '@sasza/react-panzoom'

const Preview = () => {
    const dispatch = useDispatch();
    const zoom = useSelector((state) => state.zoom.value)
    const page = useSelector((state) => state.page.current)
    const currStep = useSelector((state) => state.page.currStep)

    const template = useSelector((state) => state.template.template)
    const loading = useSelector((state) => state.template.loading)

    const zoomPage = useSelector((state) => state.page.disableZoom)


    const { id } = useParams();
    useEffect(() => {
        dispatch(fetchTemplate(id))
    }, [])

    // useEffect(() => {
    //     const mozZoom = (num) => {
    //         return Math.round(num) / 100;
    //     }

    //     const mainContentZoom = document.getElementById("preview");
    //     if (mainContentZoom) {
    //         mainContentZoom.style.zoom = `${zoom}%`;
    //         // mainContentZoom.style.setProperty('-moz-transform', `scale(${mozZoom(zoom,zoom)})`, '')
    //     }
    //     if (zoom <= 10) { dispatch(incremented()) }
    //     if (zoom >= 130) { dispatch(decremented()) }
    // }, [zoom]);

    let state = {};
    const setTemplate = () => {
        dispatch(totalPages(template.pages.length - 1));
        state = {
            data: template.pages,
            index: page,
        }
    }

    if (loading === 'loaded') {
        setTemplate()
    }

    const Type = () => {
        if (template.type === 'cocktail') {
            if (currStep < 5) {
                return <PanZoom><Page state={state} data={template} /></PanZoom>
            } else { return <Pages /> }

        }
        if (template.type === 'combo') {
            if (currStep < 3) {
                return <PanZoom><ComboPages state={state} data={template} /></PanZoom>
            } else { return <Pages /> }
        }
        if (template.type === 'catalog') {
            if (currStep < 5) {
                return <PanZoom><CatalogPages state={state} data={template} /></PanZoom>
            } else { return <Pages /> }
        }
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <section id='preview' className="flex justify-center items-center mx-auto relative">
                {loading === 'loaded' ? Type() : <Loader />}
            </section>
        </div>
    )
}

export default Preview