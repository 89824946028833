import React, { useState, useCallback, useEffect } from 'react'
import { Button } from "@material-tailwind/react";
import { useDropzone } from 'react-dropzone'

import { LuUpload } from "react-icons/lu";

import { useDispatch, useSelector } from 'react-redux';
import { loadLogo, removeLogo } from './../../../../lib/features/layout/page';

import axios from '../../../../axios'

const ClientLogo = () => {

    const dispatch = useDispatch();

    const logo = useSelector((state) => state.page.logo)

    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        if (selectedImage !== '') {
            dispatch(loadLogo(URL.createObjectURL(selectedImage)))
        }
    }, [selectedImage])

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles) {
            // try {
            //     const formData = new FormData();
            //     const file = acceptedFiles[0]
            //     formData.append('image', file);
            //     const { data } = await axios.post('/upload', formData);
            //     const UploadImage = data.url;
            //     dispatch(loadLogo(UploadImage))
            // } catch (err) {
            //     console.warn(err);
            //     alert('Error upload file')
            // }

             try {
                const formData = new FormData();
                const file = acceptedFiles[0]
                // formData.append('image', file);
                // const { data } = await axios.post('/upload', formData);
                // const UploadImage = data.url;
                setSelectedImage(file);
                dispatch(loadLogo(file))
            } catch (err) {
                console.warn(err);
                alert('Error upload file')
            }
            
        }
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const handleRemoveLogo = () => {
        dispatch(removeLogo())
    }

    return (
        <>
            <div className="text-2xl font-semibold py-2">Ανεβάστε το λογότυπό σας</div>
            <div {...getRootProps()} className="mt-5">
                <div className="mt-5">
                    {logo ?
                        (
                            <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-white p-6 text-center hover:bg-gray-100">
                                <div className='text-center'>
                                    <img src={logo} alt="logo" style={{ width: '160px' }} />

                                    {/* {selectedImage !== '' ? <img width="250px" src={URL.createObjectURL(selectedImage)} alt="not found" /> : ''} */}
                                </div>
                            </label>
                        )
                        : (
                            <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-white p-6 text-center hover:bg-gray-100">
                                <LuUpload className="text-4xl" />
                                <h2 className="mt-4 text-lg font-medium text-gray-700 tracking-wide">Click to upload  or drag and drop</h2>
                                <p className="mt-2 text-gray-500 tracking-wide text-xs">Μορφές αρχείων SVG, PNG, JPG or GIF</p>
                            </label>
                        )}
                    <input id="dropzone-file" type="file" className="hidden"  {...getInputProps()} />
                </div>
            </div>
            {logo ?
                <Button onClick={handleRemoveLogo} className="mt-5 capitalize" color="red" >Διαγραφή</Button>
                : ''}
        </>
    )
}

export default React.memo(ClientLogo)