import React from 'react'
import { Checkbox } from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { addSignature } from '../../../../lib/features/menu/menuSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CoctailsPrice from './../../price/coctailsPrice';
import { addTemplateMenuOrder } from './../../../../lib/features/template/templateSlice'
import { CiViewList } from "react-icons/ci";

const InputPrice = () => {
    const dispatch = useDispatch()

    const menu = useSelector((state) => state.template.template)
    const page = useSelector((state) => state.page.current)

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(menu.pages[page][0].items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch(addTemplateMenuOrder({ data: items, page: page }))
    }

    function getStyle(style, snapshot) {
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            transitionDuration: `0.001s`,
        };
    }

    return (
        <>
            <div className="text-2xl font-semibold py-2 relative">Συμπληρώστε τιμές</div>
            <div className='mt-8'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="menuitems">
                        {(provided) => (
                            <div className="menuitems" {...provided.droppableProps} ref={provided.innerRef}>
                                {menu.pages[page][0].items.length === 0 ?
                                    (
                                        <div className='flex justify-center flex-col text-center mx-auto mt-20'>
                                            <div className='text-md'>Αυτή η σελίδα δεν περιέχει προϊόντα</div>
                                            <div className='flex justify-center'>
                                                <CiViewList className='h-52 w-52 opacity-10' />
                                            </div>
                                        </div>

                                    ) : (
                                        menu.pages[page][0].items.map((item, idx) => (
                                            <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getStyle(provided.draggableProps.style, snapshot)}
                                                    >
                                                        <CoctailsPrice
                                                            key={item.id}
                                                            item={item} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )))
                                }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div style={{ position: 'fixed', zIndex: '1000', bottom: '80px', background: '#ffffff', padding: '10px 140px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {/* <div className='flex items-center'>
                    Προσθήκη <i>"Απολαύστε Υπεύθυνα"</i> <Checkbox defaultChecked className='pl-4'/>
                </div> */}
                {menu.pages[page][0].items.length != 0 ? (
                    <div className='flex items-center'>
                        Προσθήκη Signature <Checkbox className='pl-4' onChange={() => dispatch(addSignature())} />
                    </div>
                ) : ''}
            </div>
        </>
    )
}

export default InputPrice