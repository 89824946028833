import React from 'react'

const Variation3 = ({ state, styles }) => {

    {/* BEIGE 03 , BEIGE 06, BLACK 04, WHITE 01, WHITE 05 */ }
    return (
        <div className='menu-item-wrapper realtive'>
            <div className='block-1 relative'>
                <div className='menu-item-title'
                    style={{
                        fontSize: `${styles.titleFontSize}`,
                        fontWeight: '500',
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.titleColor}`,
                        display: 'block',
                        textTransform:  `${styles.textTransform}`
                    }}> {state.valueEng} </div>
                <div className='menu-item-description'
                    style={{
                        fontSize: `${styles.subtitleFontSize}`,
                        fontFamily: `${styles.subtitleFontFamily}`,
                        color: `${styles.subtitleColor}`,
                        textTransform: `${styles.textTransform}`
                    }}> {state.descriptionEng} </div>
            </div>
            <div className='menu-item-price'
                style={{
                    fontSize: `${styles.priceFontSize}`,
                    fontFamily: `${styles.priceFontFamily}`,
                    color: `${styles.priceColor}`
                }}> {state.price ? state.price + ' €' : ' €'} </div>
        </div>
    )
}

export default Variation3