export const data = [
    {
        label: 'Schweppes Cocktails by Vasilis Kyritsis',
        value: 'Schweppes Cocktails by Vasilis Kyritsis',
        className: 'title-menu-list notouch',
        children: [
            {
                label: 'CCH Pr. Spirits',
                value: 'CCH Pr. Spirits',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Pink G&T - Fords Gin / Elderflower Liqueur / Schweppes Pink Tonic Style  / Orange Bitters',
                        valueEng: 'Pink G&T',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Elderflower Liqueur  / Schweppes Pink Tonic Style  / Orange Bitters',
                        descriptionGr: '',
                        id: '1',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Reborn G&T - Fords Gin / Mint / Vanilla & Citrus Cordial / Schweppes Indian Tonic Water',
                        valueEng: 'Reborn G&T',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Mint / Vanilla & Citrus Cordial  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '2',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Salty Cat - Finlandia Vodka / Yellow Grapefruit Juice / Honey  / Schweppes Pink Tonic Style  / Salt',
                        valueEng: 'Salty Cat',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Yellow Grapefruit Juice  / Honey  / Schweppes Pink Tonic Style  / Salt',
                        descriptionGr: '',
                        id: '3',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Cosmo 75 - Finlandia Vodka / Orange Liqueur  / Lemon  / Agave  / Schweppes Indian Tonic Water',
                        valueEng: 'Cosmo 75',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Orange Liqueur  / Lemon  / Agave  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '4',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Chili Mexican Mojito - el Jimador Blanco  / Chilly Syrup  / Mint  / Lime  / Schweppes Pink Grapefruit',
                        valueEng: 'Chili Mexican Mojito',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Chilly Syrup  / Mint  / Lime  / Schweppes Pink Grapefruit',
                        descriptionGr: '',
                        id: '5',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Manhattan Spagliato - Woodford Reserve / Sweet Vermouth  / Angostura Bitters  / Schweppes Soda Water',
                        valueEng: 'Manhattan Spagliato',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve / Sweet Vermouth  / Angostura Bitters  / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '6',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Ginger Mule - Fords Gin / Lemon Cordial / Schweppes Ginger Ale',
                        valueEng: 'Ginger Mule',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Lemon Cordial / Schweppes Ginger Ale',
                        descriptionGr: '',
                        id: '7',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Pink Cobbler - Brugal Anejo / Berries Mix  / Honey  / Schweppes Pink Tonic Style',
                        valueEng: 'Pink Cobbler',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / Berries Mix / Honey  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '8',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Whisky Highball - The Famous Grouse / Maple Syrup  / Lemon  / Orange Bitters  / Schweppes Indian Tonic Water',
                        valueEng: 'Whisky Highball',
                        valueGr: '',
                        descriptionEng: 'The Famous Grouse / Maple Syrup  / Lemon  / Orange Bitters  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '9',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Smokey Rickey - Finlandia Vodka / Mezcal  / Lemon Cordial  / Schweppes Pink grapefruit',
                        valueEng: 'Smokey Rickey',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Mezcal / Lemon Cordial  / Schweppes Pink grapefruit',
                        descriptionGr: '',
                        id: '10',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Greek Fusion (umami) - Fords Gin / M Dry Mastiha  / Tomato Water  / Honey  / Lemon  / Scwheppes Orangeade with Orange Blossom',
                        valueEng: 'Greek Fusion (umami)',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / M Dry Mastiha / Tomato Water  / Honey  / Lemon  / Scwheppes Orangeade with Orange Blossom',
                        descriptionGr: '',
                        id: '11',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Latin Highball - el Jimador Reposado / Tsipouro Dekaraki Single Variety Grape Muscat  / Honey  / Lime  / Salt Solution  / Schweppes Pink Tonic Style',
                        valueEng: 'Latin Highball',
                        valueGr: '',
                        descriptionEng: 'el Jimador Reposado / Tsipouro Dekaraki Single Variety Grape Muscat  / Honey  / Lime  / Salt Solution  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '12',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Bee’s Knees Highball - Fords Gin / Honey  / Passion Fruit  / Scwheppes Orangeade with Orange Blossom',
                        valueEng: 'Bee’s Knees Highball',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Honey / Passion Fruit  / Scwheppes Orangeade with Orange Blossom',
                        descriptionGr: '',
                        id: '13',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Pink Lemonade - Finlandia Vodka / Mango Syrup  / Lemon  / Schweppes Lemonade with Hibiscus & Pergamon',
                        valueEng: 'Pink Lemonade',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Mango Syrup / Lemon  / Schweppes Lemonade with Hibiscus & Pergamon',
                        descriptionGr: '',
                        id: '14',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Cucumber Smash & Bubbles - Fords Gin / Cucumber  / Orgeat  / Lemon  / Schweppes Pink Grapefruit',
                        valueEng: 'Cucumber Smash & Bubbles',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Cucumber / Orgeat / Lemon  / Schweppes Pink Grapefruit',
                        descriptionGr: '',
                        id: '15',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Pomegranate Julep - el Jimador Blanco / Agave  / Mint  / Schweppes Pomegranate',
                        valueEng: 'Pomegranate Julep',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Agave / Mint  / Schweppes Pomegranate',
                        descriptionGr: '',
                        id: '16',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Espresso Highball - Brugal Anejo / Espresso  / Coffee Liqueur  / Sugar  / Schweppes Indian Tonic Water',
                        valueEng: 'Espresso Highball',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / Espresso / Coffee Liqueur  / Sugar  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '17',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Ramos Twist - Fords Gin / Heavy Cream / Vanilla  / Rose Water  / Lemon  / Schweppes Pink Tonic Style',
                        valueEng: 'Ramos Twist',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Heavy Cream / Vanilla  / Rose Water  / Lemon  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '18',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Apple Pleasure - Finlandia Vodka / Green Apple  / Caramel  / Mint  / Lemon  / Schweppes Indian Tonic Water',
                        valueEng: 'Apple Pleasure',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Green Apple / Caramel  / Mint  / Lemon  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '19',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'South On the Side - el Jimador Reposado  / Banana  / Honey  / Lime  / Angostura Bitters  / Schweppes Pink Tonic Style',
                        valueEng: 'South On the Side',
                        valueGr: '',
                        descriptionEng: 'el Jimador Reposado / Banana / Honey  / Lime  / Angostura Bitters  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '20',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                ],
            },



            {
                label: 'Pr. Spirits',
                value: 'Pr.Spirits_02',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Pink G&T - London Dry Gin / Elderflower Liqueur / Schweppes Pink Tonic Style  / Orange Bitters',
                        valueEng: 'Pink G&T',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Elderflower Liqueur  / Schweppes Pink Tonic Style  / Orange Bitters',
                        descriptionGr: '',
                        id: '21',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Reborn G&T - London Dry Gin / Mint / Vanilla & Citrus Cordial  / Schweppes Indian Tonic Water',
                        valueEng: 'Reborn G&T',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Mint / Vanilla & Citrus Cordial  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '22',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Salty Cat - Vodka / Yellow Grapefruit Juice  / Honey  / Schweppes Pink Tonic Style  / Salt',
                        valueEng: 'Salty Cat',
                        valueGr: '',
                        descriptionEng: 'Vodka / Yellow Grapefruit Juice  / Honey  / Schweppes Pink Tonic Style  / Salt',
                        descriptionGr: '',
                        id: '23',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Cosmo 75 - Vodka / Orange Liqueur / Lemon  / Agave  / Schweppes Indian Tonic Water',
                        valueEng: 'Cosmo 75',
                        valueGr: '',
                        descriptionEng: 'Vodka / Orange Liqueur / Lemon / Agave  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '24',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Chili Mexican Mojito - Tequila Blanco / Chilly Syrup  / Mint  / Lime  / Schweppes Pink Grapefruit',
                        valueEng: 'Chili Mexican Mojito',
                        valueGr: '',
                        descriptionEng: 'Tequila Blanco / Chilly Syrup / Mint  / Lime  / Schweppes Pink Grapefruit',
                        descriptionGr: '',
                        id: '25',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Manhattan Spagliato - Bourbon Whiskey / Sweet Vermouth  / Angostura Bitters  / Schweppes Soda Water',
                        valueEng: 'Manhattan Spagliato',
                        valueGr: '',
                        descriptionEng: 'Bourbon Whiskey / Sweet Vermouth  / Angostura Bitters  / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '26',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Ginger Mule - London Dry Gin / Lemon Cordial  / Schweppes Ginger Ale',
                        valueEng: 'Ginger Mule',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Lemon Cordial / Schweppes Ginger Ale',
                        descriptionGr: '',
                        id: '27',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Basil Fizz - White Rum / Basil / Agave / Lemon  / Schweppes Lemonade with Hibiscus & Pergamon',
                        valueEng: 'Basil Fizz',
                        valueGr: '',
                        descriptionEng: 'White Rum / Basil / Agave / Lemon  / Schweppes Lemonade with Hibiscus & Pergamon',
                        descriptionGr: '',
                        id: '28',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Pink Cobbler - Aged Rum / Berries Mix / Honey  / Schweppes Pink Tonic Style',
                        valueEng: 'Pink Cobbler',
                        valueGr: '',
                        descriptionEng: 'Aged Rum / Berries Mix / Honey / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '29',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Whisky Highball - Scotch Whisky / Maple Syrup  / Lemon  / Orange Bitters  / Schweppes Indian Tonic Water',
                        valueEng: 'Whisky Highball',
                        valueGr: '',
                        descriptionEng: 'Scotch Whisky / Maple Syrup / Lemon  / Orange Bitters  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '30',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Twisted Mizuwari - Jack Daniels Old  No7  / Japanese Whisky  / Fig Syrup  / Schweppes Soda Water',
                        valueEng: 'Twisted Mizuwari',
                        valueGr: '',
                        descriptionEng: 'Jack Daniels Old No7  / Japanese Whisky  / Fig Syrup  / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '31',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Smokey Rickey - Vodka / Mezcal / Lemon Cordial  / Schweppes Pink grapefruit',
                        valueEng: 'Smokey Rickey',
                        valueGr: '',
                        descriptionEng: 'Vodka / Mezcal / Lemon Cordial / Schweppes Pink grapefruit',
                        descriptionGr: '',
                        id: '32',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Greek Fusion (umami) - London Dry Gin / Mastiha  / Tomato Water  / Honey  / Lemon  / Scwheppes Orangeade with Orange Blossom',
                        valueEng: 'Greek Fusion (umami)',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Mastiha / Tomato Water  / Honey  / Lemon  / Scwheppes Orangeade with Orange Blossom',
                        descriptionGr: '',
                        id: '33',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Latin Highball - Tequila Reposado / Tsipouro  / Honey  / Lime  / Salt Solution  / Schweppes Pink Tonic Style',
                        valueEng: 'Latin Highball',
                        valueGr: '',
                        descriptionEng: 'Tequila Reposado / Tsipouro / Honey  / Lime  / Salt Solution  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '34',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Bee’s Knees Highball - London Dry Gin / Honey  / Passion Fruit  / Scwheppes Orangeade with Orange Blossom',
                        valueEng: 'Bee’s Knees Highball',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Honey / Passion Fruit  / Scwheppes Orangeade with Orange Blossom',
                        descriptionGr: '',
                        id: '35',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Pink Lemonade - Vodka / Mango Syrup / Lemon  / Schweppes Lemonade with Hibiscus & Pergamon',
                        valueEng: 'Pink Lemonade',
                        valueGr: '',
                        descriptionEng: 'Vodka / Mango Syrup / Lemon / Schweppes Lemonade with Hibiscus & Pergamon',
                        descriptionGr: '',
                        id: '36',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Apricot Spritz - White Bitter Aperitif / Apricot Brandy  / Honey  / Schweppes Pink Grapefruit  / Prosecco',
                        valueEng: 'Apricot Spritz',
                        valueGr: '',
                        descriptionEng: 'White Bitter Aperitif / Apricot Brandy  / Honey  / Schweppes Pink Grapefruit  / Prosecco',
                        descriptionGr: '',
                        id: '37',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Strawberry Collins - White Rum / Strawberry  / Vanilla  / Lemon  / Schweppes Pink Grapefruit',
                        valueEng: 'Strawberry Collins',
                        valueGr: '',
                        descriptionEng: 'White Rum / Strawberry / Vanilla  / Lemon  / Schweppes Pink Grapefruit',
                        descriptionGr: '',
                        id: '38',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Cucumber Smash & Bubbles - London Dry Gin  / Cucumber  / Orgeat  / Lemon  / Schweppes Pink Grapefruit',
                        valueEng: 'Cucumber Smash & Bubbles',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Cucumber / Orgeat  / Lemon  / Schweppes Pink Grapefruit',
                        descriptionGr: '',
                        id: '39',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Pomegranate Julep - Tequila Blanco / Agave  / Mint  / Schweppes Pomegranate',
                        valueEng: 'Pomegranate Julep',
                        valueGr: '',
                        descriptionEng: 'Tequila Blanco / Agave / Mint / Schweppes Pomegranate',
                        descriptionGr: '',
                        id: '40',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Cocofizz - White Rum / Coconut / Pineapple  / Schweppes Pink Tonic Style',
                        valueEng: 'Cocofizz',
                        valueGr: '',
                        descriptionEng: 'White Rum / Coconut / Pineapple / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '41',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Espresso Highball - Aged Rum / Espresso / Coffee Liqueur  / Sugar  / Schweppes Indian Tonic Water',
                        valueEng: 'Espresso Highball',
                        valueGr: '',
                        descriptionEng: 'Aged Rum / Espresso / Coffee Liqueur  / Sugar  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '42',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Ramos Twist - London Dry Gin / Heavy Cream  / Vanilla  / Rose Water  / Lemon  / Schweppes Pink Tonic Style',
                        valueEng: 'Ramos Twist',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Heavy Cream / Vanilla  / Rose Water  / Lemon  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '43',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'Apple Pleasure - Vodka / Green Apple / Caramel  / Mint  / Lemon  / Schweppes Indian Tonic Water',
                        valueEng: 'Apple Pleasure',
                        valueGr: '',
                        descriptionEng: 'Vodka / Green Apple / Caramel / Mint  / Lemon  / Schweppes Indian Tonic Water',
                        descriptionGr: '',
                        id: '44',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                    {
                        label: 'South On the Side - Tequila Reposado / Banana  / Honey  / Lime  / Angostura Bitters  / Schweppes Pink Tonic Style',
                        valueEng: 'South On the Side',
                        valueGr: '',
                        descriptionEng: 'Tequila Reposado / Banana / Honey  / Lime  / Angostura Bitters  / Schweppes Pink Tonic Style',
                        descriptionGr: '',
                        id: '45',
                        price: '',
                        className: "desc-list",
                        signature: '/images/SIGNATURE-01.png'
                    },
                ],
            },
        ],
    },


    {
        label: 'Three Cent Cocktails',
        value: 'Three Cent Cocktails',
        className: 'title-menu-list notouch',
        children: [
            {
                label: 'CCH Pr. Spirits',
                value: 'CCH Pr. Spirits_003',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Paloma - el Jimador Blanco / Three Cents Pink Grapefruit Soda / lime juice',
                        valueEng: 'Paloma',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Three Cents Pink Grapefruit Soda / lime juice',
                        descriptionGr: '',
                        id: '46',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Aegean G&T - Fords Gin  / Three Cents Aegean Tonic',
                        valueEng: 'Aegean G&T',
                        valueGr: '',
                        descriptionEng: 'Fords Gin  / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '47',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Prickly Lady - Finlandia Vodka / Prickly Pear cordial / Three Cents Sparkling Lemonade',
                        valueEng: 'Prickly Lady',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Prickly Pear cordial / Three Cents Sparkling Lemonade',
                        descriptionGr: '',
                        id: '48',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Gin Basil Smash spritz - Fords Gin / lemon & basil cordial / Three Cents Lemon Tonic',
                        valueEng: 'Gin Basil Smash spritz',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / lemon & basil cordial / Three Cents Lemon Tonic',
                        descriptionGr: '',
                        id: '49',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Tenderness - M Dry Mastiha / Clement rhum agricole / Basil leaves / lime juice / Three Cents Mandarin & Begamot Soda',
                        valueEng: 'Tenderness',
                        valueGr: '',
                        descriptionEng: 'M Dry Mastiha / Clement rhum agricole / Basil leaves / lime juice / Three Cents Mandarin & Begamot Soda',
                        descriptionGr: '',
                        id: '50',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dark & Stormy - Brugal Anejo / lime / Sugar syrup / Three Cents Ginger Beer',
                        valueEng: 'Dark & Stormy',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / lime / Sugar syrup / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '51',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Flower Highball - The Famous Grouse Smoky Black / elderflower liquer / Two Cents Plain Soda',
                        valueEng: 'Flower Highball',
                        valueGr: '',
                        descriptionEng: 'The Famous Grouse Smoky Black / elderflower liquer / Two Cents Plain Soda',
                        descriptionGr: '',
                        id: '52',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Smoky mandarin - Finlandia Vodka / Smoked whiskey / Three Cents Mnadarin & Bergamot Soda',
                        valueEng: 'Smoky mandarin',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Smoked whiskey / Three Cents Mnadarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '53',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Tropical Southsida smash - Finlandia Vodka / lime juice / Simple syrup / Mint leaves / Three Cents Pineapple Soda',
                        valueEng: 'Tropical Southsida smash',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / lime juice / Simple syrup / Mint leaves / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '54',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Mediterannean Spritz - Fords Gin / strawberry liqueur / lime juice / Three Cents Aegean Tonic',
                        valueEng: 'Mediterannean Spritz',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / strawberry liqueur / lime juice / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '55',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Summertime Madness - Fords Gin / Mellon liquer / Strawberry cordial / Three Cents Tonic Water',
                        valueEng: 'Summertime Madness',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Mellon liquer / Strawberry cordial / Three Cents Tonic Water',
                        descriptionGr: '',
                        id: '56',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Ultimate Vodka Tonic - Finlandia Vodka / Three Cents Dry Tonic',
                        valueEng: 'Ultimate Vodka Tonic',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Three Cents Dry Tonic',
                        descriptionGr: '',
                        id: '57',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Singapore Sling 2.0 - Fords Gin / Med Liqueur / lemon juice / Marasquino Liqueur / Angostura bitters / Three Cents Pineapple Soda',
                        valueEng: 'Singapore Sling 2.0',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Med Liqueur / Lemon juice / Marasquino Liqueur / Angostura bitters / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '58',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Holiday Whiskey Fizz - Woodford Reserve Rye / lime juice / Pomegranate syrup / Two Cents Plain Soda',
                        valueEng: 'Holiday Whiskey Fizz',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve Rye / lime juice / Pomegranate syrup / Two Cents Plain Soda',
                        descriptionGr: '',
                        id: '59',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Al Pastor Paloma - el Jimador Blanco / Celery-coriander cordial / Three Cents Pineapple Soda',
                        valueEng: 'Al Pastor Paloma',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Celery-coriander cordial / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '60',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Fish House Punch - Cognac VS / Brugal Anejo / Crème de Peche / Three Cents Sparkling Lemonade',
                        valueEng: 'Fish House Punch',
                        valueGr: '',
                        descriptionEng: 'Cognac VS / Brugal Anejo / Crème de Peche / Three Cents Sparkling Lemonade',
                        descriptionGr: '',
                        id: '61',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'London Spritz - Aperitif / Fords Gin / Three Cents Pink Grapefruit Soda',
                        valueEng: 'London Spritz',
                        valueGr: '',
                        descriptionEng: 'Aperitif / Fords Gin / Three Cents Pink Grapefruit Soda',
                        descriptionGr: '',
                        id: '62',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cantarito - el Jimador Blanco / Fresh orange juice / lime juice & pink grapefuit juice / Three Cents Pink Grapefruit Soda / Salt',
                        valueEng: 'Cantarito',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Fresh orange juice / lime juice & pink grapefuit juice / Three Cents Pink Grapefruit Soda / Salt',
                        descriptionGr: '',
                        id: '63',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Citrus Collins - Finlandia vodka / Eldeflower Cordial / Three Cents Mandarin & Bergamot Soda',
                        valueEng: 'Citrus Collins',
                        valueGr: '',
                        descriptionEng: 'Finlandia vodka / Eldeflower Cordial / Three Cents Mandarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '64',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dark & Salty - Brugal Anejo / Salted Cinnamon Syrup / lime Juice / Three Cents Ginger Beer',
                        valueEng: 'Dark & Salty',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / Salted Cinnamon Syrup / lime Juice / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '65',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Pa-ssion Loma - el Jimador Blanco / Passion Fruit Oleo / Pink Grapefruit Juice / Three Cents Pink Grapefruit Soda',
                        valueEng: 'Pa-ssion Loma',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Passion Fruit Oleo / Pink Grapefruit Juice / Three Cents Pink Grapefruit Soda',
                        descriptionGr: '',
                        id: '66',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Okinawa - Fords Gin / Tomato Liqueur / Olive Brine / Three Cents Aegean Tonic',
                        valueEng: 'Okinawa',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Tomato Liqueur / Olive Brine / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '67',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cherry Land - el Jimador Blanco infused with beetroot / Peach liqueur / lime Juice / Three Cents Cherry Soda',
                        valueEng: 'Cherry Land',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco infused with beetroot / Peach liqueur / lime Juice / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '68',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Better Bitter - Fords Gin / Maraschino Liqueur / Plum Bitter / Three Cents Lemon Tonic',
                        valueEng: 'Better Bitter',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Maraschino Liqueur / Plum Bitter / Three Cents Lemon Tonic',
                        descriptionGr: '',
                        id: '69',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Pr. Spirits',
                value: 'Pr. Spirits-003',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Paloma - Tequila blanco / Three Cents Pink Grapefruit Soda / lime juice',
                        valueEng: 'Paloma',
                        valueGr: '',
                        descriptionEng: 'Tequila blanco / Three Cents Pink Grapefruit Soda / lime juice',
                        descriptionGr: '',
                        id: '70',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Aegean G&T - London Dry Gin  / Three Cents Agean Tonic',
                        valueEng: 'Aegean G&T',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin  / Three Cents Agean Tonic',
                        descriptionGr: '',
                        id: '71',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Lets go Bananas - Aperitif / liqueur banane du Bresil / lime juice / Three Cents Pineapple Soda / Pineapple carpaccio',
                        valueEng: 'Lets go Bananas',
                        valueGr: '',
                        descriptionEng: 'Aperitif / liqueur banane du Bresil / lime juice / Three Cents Pineapple Soda / Pineapple carpaccio',
                        descriptionGr: '',
                        id: '72',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Prickly Lady - Vodka / Prickly Pear cordial / Three Cents Sparkling Lemonade',
                        valueEng: 'Prickly Lady',
                        valueGr: '',
                        descriptionEng: 'Vodka / Prickly Pear cordial / Three Cents Sparkling Lemonade',
                        descriptionGr: '',
                        id: '73',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Alice in Wonderland - Bitter / Melon liqueur / lime juice / Three Cents Cherry Soda',
                        valueEng: 'Alice in Wonderland',
                        valueGr: '',
                        descriptionEng: 'Bitter / Melon liqueur / lime juice / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '74',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Gin Basil Smash spritz - London Dry Gin / lemon & basil cordial / Three Cents Lemon Tonic',
                        valueEng: 'Gin Basil Smash spritz',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / lemon & basil cordial / Three Cents Lemon Tonic',
                        descriptionGr: '',
                        id: '75',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Sir Collins - Naked Malt / Apricot-Pear cordial / Three Cents Mandarin & Bergamot Soda',
                        valueEng: 'Sir Collins',
                        valueGr: '',
                        descriptionEng: 'Naked Malt / Apricot-Pear cordial / Three Cents Mandarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '76',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cherry Bomb - Woodford Reserve Bourbon / lime juice / vanilla syrup / Three Cents Cherry Soda',
                        valueEng: 'Cherry Bomb',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve Bourbon / lime juice / vanilla syrup / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '77',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Tenderness - Mastiha / Clement rhum agricole / Basil leaves / lime juice / Three Cents Mandarin & Begamot Soda',
                        valueEng: 'Tenderness',
                        valueGr: '',
                        descriptionEng: 'Mastiha / Clement rhum agricole / Basil leaves / lime juice / Three Cents Mandarin & Begamot Soda',
                        descriptionGr: '',
                        id: '78',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Winter Americano - Red Bitter / Red vermouth / Three Cents Mandarin & Bergamot Soda',
                        valueEng: 'Winter Americano',
                        valueGr: '',
                        descriptionEng: 'Red Bitter / Red vermouth / Three Cents Mandarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '79',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Vermouth Cooler - White vermouth / Three Cents Cherry Soda',
                        valueEng: 'Vermouth Cooler',
                        valueGr: '',
                        descriptionEng: 'White vermouth / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '80',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dark & Stormy - Aged Rum / lime / Sugar syrup / Three Cents Ginger Beer',
                        valueEng: 'Dark & Stormy',
                        valueGr: '',
                        descriptionEng: 'Aged Rum / lime / Sugar syrup / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '81',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Flower Highball - Blended Scotch Whiskey / elderflower liquer / Two Cents Plain Soda',
                        valueEng: 'Flower Highball',
                        valueGr: '',
                        descriptionEng: 'Blended Scotch Whiskey / elderflower liquer / Two Cents Plain Soda',
                        descriptionGr: '',
                        id: '82',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Smoky mandarin - Vodka / Smoked whiskey / Three Cents Mandarin & Bergamot Soda',
                        valueEng: 'Smoky mandarin',
                        valueGr: '',
                        descriptionEng: 'Vodka / Smoked whiskey / Three Cents Mandarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '83',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Fish House Punch - Cognac / Peach Liqueur / Three Cents Sparkling Lemonade',
                        valueEng: 'Fish House Punch',
                        valueGr: '',
                        descriptionEng: 'Cognac / Peach Liqueur / Three Cents Sparkling Lemonade',
                        descriptionGr: '',
                        id: '84',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cherry Rush - Sweet Vermouth / Jasmine Liqueur / Three Cents Cherry Soda',
                        valueEng: 'Cherry Rush',
                        valueGr: '',
                        descriptionEng: 'Sweet Vermouth / Jasmine Liqueur / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '85',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Caribbean Mule - Spiced rum / lime juice / Three Cents Ginger Beer',
                        valueEng: 'Caribbean Mule',
                        valueGr: '',
                        descriptionEng: 'Spiced rum / lime juice / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '86',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Tropical Southsida smash - Vodka / lime juice / Simple syrup / Mint leaves / Three Cents Pineapple Soda',
                        valueEng: 'Tropical Southsida smash',
                        valueGr: '',
                        descriptionEng: 'Vodka / lime juice / Simple syrup / Mint leaves / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '87',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cherry Tai - Jamaican rum / Triple Sec / lime juice / Three Cents Cherry Soda',
                        valueEng: 'Cherry Tai',
                        valueGr: '',
                        descriptionEng: 'Jamaican rum / Triple Sec / lime juice / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '88',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Mediterannean Spritz - London dry gin / strawberry liqueur / lime juice / Three Cents Aegean Tonic',
                        valueEng: 'Mediterannean Spritz',
                        valueGr: '',
                        descriptionEng: 'London dry gin / strawberry liqueur / lime juice / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '89',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Summertime Madness - London Dry Gin / Mellon liquer / Strawberry cordial / Three Cents Tonic Water',
                        valueEng: 'Summertime Madness',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Mellon liquer / Strawberry cordial / Three Cents Tonic Water',
                        descriptionGr: '',
                        id: '90',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Bitter Aegean - Red Bitter Liquer / Three Cents Aegean Tonic',
                        valueEng: 'Bitter Aegean',
                        valueGr: '',
                        descriptionEng: 'Red Bitter Liquer / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '91',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Ultimate Vodka Tonic - Vodka / Three Cents Dry Tonic',
                        valueEng: 'Ultimate Vodka Tonic',
                        valueGr: '',
                        descriptionEng: 'Vodka / Three Cents Dry Tonic',
                        descriptionGr: '',
                        id: '92',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Singapore Sling 2.0 - London Dry Gin / Med Liqueur / lemon juice / Marasquino Liqueur  / Angostura bitters / Three Cents Pineapple Soda',
                        valueEng: 'Singapore Sling 2.0',
                        valueGr: '',
                        descriptionEng: 'London Dry Gin / Med Liqueur / lemon juice / Marasquino Liqueur  / Angostura bitters / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '93',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Holiday Whiskey Fizz - Woodford Reserve Rye / lime juice / pomegranate syrup / Two Cents Plain Soda',
                        valueEng: 'Holiday Whiskey Fizz',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve Rye / lime juice / pomegranate syrup / Two Cents Plain Soda',
                        descriptionGr: '',
                        id: '94',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Al Pastor Paloma - Tequila blanco / Celery-coriander cordial / Three Cents Pineapple Soda',
                        valueEng: 'Al Pastor Paloma',
                        valueGr: '',
                        descriptionEng: 'Tequila blanco / Celery-coriander cordial / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '95',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Apple Pie smash - Spiced Rum / cinammon syrup / Mixer green apple puree / lime juice / Two Cents Plain Soda',
                        valueEng: 'Apple Pie smash',
                        valueGr: '',
                        descriptionEng: 'Spiced Rum / cinammon syrup / Mixer green apple puree / lime juice / Two Cents Plain Soda',
                        descriptionGr: '',
                        id: '96',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Fizzy Colada - White rum / coconut liquer / lime juice / Three Cents Pineapple Soda',
                        valueEng: 'Fizzy Colada',
                        valueGr: '',
                        descriptionEng: 'White rum / coconut liquer / lime juice / Three Cents Pineapple Soda',
                        descriptionGr: '',
                        id: '97',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Fish House Punch - Cognac VS / Aged rum / Crème de Peche / Three Cents Sparkling Lemonade',
                        valueEng: 'Fish House Punch',
                        valueGr: '',
                        descriptionEng: 'Cognac VS / Aged rum / Crème de Peche / Three Cents Sparkling Lemonade',
                        descriptionGr: '',
                        id: '98',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'London Spritz - Aperitif / london Dry Gin / Three Cents Pink Grapefruit Soda',
                        valueEng: 'London Spritz',
                        valueGr: '',
                        descriptionEng: 'Aperitif / london Dry Gin / Three Cents Pink Grapefruit Soda',
                        descriptionGr: '',
                        id: '99',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cantarito - Tequila Blanco / Fresh orange juice / lime juice & pink grapefuit juice / Three Cents Pink Grapefruit Soda / salt',
                        valueEng: 'Cantarito',
                        valueGr: '',
                        descriptionEng: 'Tequila Blanco / Fresh orange juice / lime juice & pink grapefuit juice / Three Cents Pink Grapefruit Soda / salt',
                        descriptionGr: '',
                        id: '100',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Citrus Americano - Red Bitter Liquer / Sweet Vermoouth / Three Cents Mandarin & Bergamot Soda',
                        valueEng: 'Citrus Americano',
                        valueGr: '',
                        descriptionEng: 'Red Bitter Liquer / Sweet Vermoouth / Three Cents Mandarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '101',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Jasmine - Red Bitter Liquer / Jasmine liquer / Three Cents Aegean Tonic',
                        valueEng: 'Jasmine',
                        valueGr: '',
                        descriptionEng: 'Red Bitter Liquer / Jasmine liquer / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '102',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Lemon Musk - dry Vermooth / Three Cents Lemon Tonic',
                        valueEng: 'Lemon Musk',
                        valueGr: '',
                        descriptionEng: 'dry Vermooth / Three Cents Lemon Tonic',
                        descriptionGr: '',
                        id: '103',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Citrus Collins - Vodka / Eldeflower Cordial / Three Cents Mandarin & Bergamot Soda',
                        valueEng: 'Citrus Collins',
                        valueGr: '',
                        descriptionEng: 'Vodka / Eldeflower Cordial / Three Cents Mandarin & Bergamot Soda',
                        descriptionGr: '',
                        id: '104',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dark & Salty - Aged Rum / Salted Cinnamon Syrup / lime Juice / Three Cents Ginger Beer',
                        valueEng: 'Dark & Salty',
                        valueGr: '',
                        descriptionEng: 'Aged Rum / Salted Cinnamon Syrup / lime Juice / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '105',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Pa-ssion Loma - Tequila Blanco / Passion Fruit Oleo / Pink Grapefruit Juice / Three Cents Pink Grapefruit Soda',
                        valueEng: 'Pa-ssion Loma',
                        valueGr: '',
                        descriptionEng: 'Tequila Blanco / Passion Fruit Oleo / Pink Grapefruit Juice / Three Cents Pink Grapefruit Soda',
                        descriptionGr: '',
                        id: '106',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Okinawa - Gin / Tomato Liqueur / Olive Brine / Three Cents Aegean Tonic',
                        valueEng: 'Okinawa',
                        valueGr: '',
                        descriptionEng: 'Gin / Tomato Liqueur / Olive Brine / Three Cents Aegean Tonic',
                        descriptionGr: '',
                        id: '107',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cherry Land - Tequila Bianco infused with beetroot / Peach liqueur / lime Juice / Three Cents Cherry Soda',
                        valueEng: 'Cherry Land',
                        valueGr: '',
                        descriptionEng: 'Tequila Bianco infused with beetroot / Peach liqueur / lime Juice / Three Cents Cherry Soda',
                        descriptionGr: '',
                        id: '108',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Better Bitter - Gin / Maraschino Liqueur / Plum Bitter / Three Cents Lemon Tonic',
                        valueEng: 'Better Bitter',
                        valueGr: '',
                        descriptionEng: 'Gin / Maraschino Liqueur / Plum Bitter / Three Cents Lemon Tonic',
                        descriptionGr: '',
                        id: '109',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
        ],
    },


    {
        label: 'CCH Premium Spirits Cocktails',
        value: 'CCH Premium Spirits Cocktails_01',
        className: 'title-menu-list notouch',
        children: [
            {
                label: 'The Famous Coffee & Berries Fashioned - Famous Grouse  / Raspberry Syrup / Coffee Liquer / Angostura bitters',
                valueEng: 'The Famous Coffee & Berries Fashioned',
                valueGr: '',
                descriptionEng: 'Famous Grouse  / Raspberry Syrup / Coffee Liquer / Angostura bitters',
                descriptionGr: '',
                id: '110',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'The Famous Suffering - Famous Grouse  / Apple Liquer / Cinammon Syrup / Fresh lime juice / Angostura bitters / Three Cents Ginger Beer',
                valueEng: 'The Famous Suffering',
                valueGr: '',
                descriptionEng: 'Famous Grouse  / Apple Liquer / Cinammon Syrup / Fresh lime juice / Angostura bitters / Three Cents Ginger Beer',
                descriptionGr: '',
                id: '111',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'The Famous Smoky Black Penicillin - Famous Grouse Smoky Black / Honey Syrup / Fresh lemon juice / Ginger Water',
                valueEng: 'The Famous Smoky Black Penicillin',
                valueGr: '',
                descriptionEng: 'Famous Grouse Smoky Black / Honey Syrup / Fresh lemon juice / Ginger Water',
                descriptionGr: '',
                id: '112',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Grouse at First Sight - Famous Grouse Smoky Black / Crème de Violette / Fresh Lime juice / Schweppes Pink',
                valueEng: 'Grouse at First Sight',
                valueGr: '',
                descriptionEng: 'Famous Grouse Smoky Black / Crème de Violette / Fresh Lime juice / Schweppes Pink',
                descriptionGr: '',
                id: '113',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Naked not Ordinary Highball - Naked Malt / Apricot Liquer / Fresh Lime juice / Three Cents Mandarin & Bergamot Soda',
                valueEng: 'Naked not Ordinary Highball',
                valueGr: '',
                descriptionEng: 'Naked Malt / Apricot Liquer / Fresh Lime juice / Three Cents Mandarin & Bergamot Soda',
                descriptionGr: '',
                id: '114',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'The Naked Colada - Naked Malt / Pineapple Puree / Coconut Puree / Fresh Lime juice / Powder sugar',
                valueEng: 'The Naked Colada',
                valueGr: '',
                descriptionEng: 'Naked Malt / Pineapple Puree / Coconut Puree / Fresh Lime juice / Powder sugar',
                descriptionGr: '',
                id: '115',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Naked in the Tropics Sour - Naked Malt / Mango Puree / Orgeat Syrup / Fresh Lime juice / Pimento Dram 2.5',
                valueEng: 'Naked in the Tropics Sour',
                valueGr: '',
                descriptionEng: 'Naked Malt / Mango Puree / Orgeat Syrup / Fresh Lime juice / Pimento Dram 2.5',
                descriptionGr: '',
                id: '116',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Maestros Welcome - Brugal 1888 / Coconut Syrup / Coffee bitters',
                valueEng: 'Maestros Welcome',
                valueGr: '',
                descriptionEng: 'Brugal 1888 / Coconut Syrup / Coffee bitters',
                descriptionGr: '',
                id: '117',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Mare Tonic - Gin Mare / Three Cents Dry Tonic',
                valueEng: 'Mare Tonic',
                valueGr: '',
                descriptionEng: 'Gin Mare / Three Cents Dry Tonic',
                descriptionGr: '',
                id: '118',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Mare Cooler - Gin Mare / M Dry Mastiha / Fresh lemon juice / Two Cents Plain Soda',
                valueEng: 'Mare Cooler',
                valueGr: '',
                descriptionEng: 'Gin Mare / M Dry Mastiha / Fresh lemon juice / Two Cents Plain Soda',
                descriptionGr: '',
                id: '119',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Mare & Citrus - Gin Mare / Fresh lemon juice / Three Cents Mandarin & Bergamot Soda / Two Cents Plain Soda',
                valueEng: 'Mare & Citrus',
                valueGr: '',
                descriptionEng: 'Gin Mare / Fresh lemon juice / Three Cents Mandarin & Bergamot Soda / Two Cents Plain Soda',
                descriptionGr: '',
                id: '120',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Aegean G&T - Fords Gin / Three Cents Aegean Tonic',
                valueEng: 'Aegean G&T',
                valueGr: '',
                descriptionEng: 'Fords Gin / Three Cents Aegean Tonic',
                descriptionGr: '',
                id: '121',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Cherry Blossom Martini - Fords Gin (infused rasberries) / Dry Vermouth / Mancino Sakura Vermouth',
                valueEng: 'Cherry Blossom Martini',
                valueGr: '',
                descriptionEng: 'Fords Gin (infused rasberries) / Dry Vermouth / Mancino Sakura Vermouth',
                descriptionGr: '',
                id: '122',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Honey Derby - Jack Daniels Honey / Fresh Lime juice / Three Cents Pink Grapefruit Soda / Two Cents Plain Soda',
                valueEng: 'Honey Derby',
                valueGr: '',
                descriptionEng: 'Jack Daniels Honey / Fresh Lime juice / Three Cents Pink Grapefruit Soda / Two Cents Plain Soda',
                descriptionGr: '',
                id: '123',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Jack & Ginger - Jack Daniels Old No 7 / Three Cents Ginger Beer',
                valueEng: 'Jack & Ginger',
                valueGr: '',
                descriptionEng: 'Jack Daniels Old No 7 / Three Cents Ginger Beer',
                descriptionGr: '',
                id: '124',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Jack & Juice - Jack Daniels Old No 7 / Triple sec / Fresh Lime juice / Three Cents Sparkling Lemonade',
                valueEng: 'Jack & Juice',
                valueGr: '',
                descriptionEng: 'Jack Daniels Old No 7 / Triple sec / Fresh Lime juice / Three Cents Sparkling Lemonade',
                descriptionGr: '',
                id: '125',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Jack Daniels Old Fashioned - Jack Daniels Old No 7 / Simple Syrup / Aromatic bitters',
                valueEng: 'Jack Daniels Old Fashioned',
                valueGr: '',
                descriptionEng: 'Jack Daniels Old No 7 / Simple Syrup / Aromatic bitters',
                descriptionGr: '',
                id: '126',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Apple Tonic - Jack Daniels Apple / Three Cents Dry Tonic',
                valueEng: 'Apple Tonic',
                valueGr: '',
                descriptionEng: 'Jack Daniels Apple / Three Cents Dry Tonic',
                descriptionGr: '',
                id: '127',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Apple Spritz - Jack Daniels Apple / Prosecco / Schweppes Indian Tonic Water',
                valueEng: 'Apple Spritz',
                valueGr: '',
                descriptionEng: 'Jack Daniels Apple / Prosecco / Schweppes Indian Tonic Water',
                descriptionGr: '',
                id: '128',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Finlandia Garden - Finlandia Vodka / Schweppes Soda Water',
                valueEng: 'Finlandia Garden',
                valueGr: '',
                descriptionEng: 'Finlandia Vodka / Schweppes Soda Water',
                descriptionGr: '',
                id: '129',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Vodka Martini - Finlandia Vodka / Dry Vermouth',
                valueEng: 'Vodka Martini',
                valueGr: '',
                descriptionEng: 'Finlandia Vodka / Dry Vermouth',
                descriptionGr: '',
                id: '130',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Espresso Martini - Finlandia Vodka / Coffee Liquer / Caffe Vergnano Espresso shot / Sugar syrup',
                valueEng: 'Espresso Martini',
                valueGr: '',
                descriptionEng: 'Finlandia Vodka / Coffee Liquer / Caffe Vergnano Espresso shot / Sugar syrup',
                descriptionGr: '',
                id: '131',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Rose Spritz - Finlandia Botanical Wildberry & Rose / Lemonade / Lime juice',
                valueEng: 'Rose Spritz',
                valueGr: '',
                descriptionEng: 'Finlandia Botanical Wildberry & Rose / Lemonade / Lime juice',
                descriptionGr: '',
                id: '132',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Mint Spritz - Finlandia Botanical Cucumber & Mint / Schweppes Tonic / Lime juice',
                valueEng: 'Mint Spritz',
                valueGr: '',
                descriptionEng: 'Finlandia Botanical Cucumber & Mint / Schweppes Tonic / Lime juice',
                descriptionGr: '',
                id: '133',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Helsinki Mule - Finlandia Vodka / Fresh Lime juice / Three Cents Ginger Beer / Aromatic Bitters',
                valueEng: 'Helsinki Mule',
                valueGr: '',
                descriptionEng: 'Finlandia Vodka / Fresh Lime juice / Three Cents Ginger Beer / Aromatic Bitters',
                descriptionGr: '',
                id: '134',
                price: '',
                className: "desc-list",
                signature: ''
            },
        ],
    },


    {

        label: 'Classic cocktails',
        value: 'Classic cocktails_006',
        className: 'title-menu-list notouch',
        children: [
            {
                label: 'CCH Pr. Spirits',
                value: 'CCH Pr. Spirits_009',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Bloody Mary - Finlandia Vodka / Tomato Juice  / lemon',
                        valueEng: 'Bloody Mary',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Tomato Juice  / lemon',
                        descriptionGr: '',
                        id: '135',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Boulevardier - Woodford Reserve  / Bitter / Sweet red Vermouth',
                        valueEng: 'Boulevardier',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve  / Bitter / Sweet red Vermouth',
                        descriptionGr: '',
                        id: '136',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cosmopolitan - Finlandia Vodka / Triple sec / Amita Granberry juice',
                        valueEng: 'Cosmopolitan',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / Triple sec / Amita Granberry juice',
                        descriptionGr: '',
                        id: '137',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cuba Libre - Brugal Anejo / lime juice / Coca-Cola',
                        valueEng: 'Cuba Libre',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / lime juice / Coca-Cola',
                        descriptionGr: '',
                        id: '138',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Daiquiri - Brugal Anejo / Sugar Syrup / lime',
                        valueEng: 'Daiquiri',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / Sugar Syrup / lime',
                        descriptionGr: '',
                        id: '139',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dark n Stormy - Brugal Anejo / lime / Three Cents Ginger Beer',
                        valueEng: 'Dark n Stormy',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / lime / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '140',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Lond Island - el Jimador Blanco / Finlandia Vodka / Fords Gin / White Rum / Triple sec / lime / Coca-Cola',
                        valueEng: 'Lond Island',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Finlandia Vodka / Fords Gin / White Rum / Triple sec / lime / Coca-Cola',
                        descriptionGr: '',
                        id: '141',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Manhattan - Woodford Reserve / Rye whisky / Sweet vermouth',
                        valueEng: 'Manhattan',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve / Rye whisky / Sweet vermouth',
                        descriptionGr: '',
                        id: '142',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Margarita - el Jimaror Blanco / Triple sec / lime',
                        valueEng: 'Margarita',
                        valueGr: '',
                        descriptionEng: 'el Jimaror Blanco / Triple sec / lime',
                        descriptionGr: '',
                        id: '143',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dry Martini - Fords Gin / Dry vermouth',
                        valueEng: 'Dry Martini',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Dry vermouth',
                        descriptionGr: '',
                        id: '144',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Mojito - Brugal Anejo / Sugar syrup / lime / Schweppes soda',
                        valueEng: 'Mojito',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / Sugar syrup / lime / Schweppes soda',
                        descriptionGr: '',
                        id: '145',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Moscow Mule - Finlandia Vodka / lime  / Sugar syrup / Three Cents Ginger Beer',
                        valueEng: 'Moscow Mule',
                        valueGr: '',
                        descriptionEng: 'Finlandia Vodka / lime  / Sugar syrup / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '146',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Negroni - Fords Gin / Bitter / Sweet red Vermouth',
                        valueEng: 'Negroni',
                        valueGr: '',
                        descriptionEng: 'Fords Gin / Bitter / Sweet red Vermouth',
                        descriptionGr: '',
                        id: '147',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Pina Colada - Brugal Anejo / Coconut creme / lime / Amita Pinapple',
                        valueEng: 'Pina Colada',
                        valueGr: '',
                        descriptionEng: 'Brugal Anejo / Coconut creme / lime / Amita Pinapple',
                        descriptionGr: '',
                        id: '148',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Whiskey Sour - Woodford Reserve / lemon / sugar syrup / Orange bitter',
                        valueEng: 'Whiskey Sour',
                        valueGr: '',
                        descriptionEng: 'Woodford Reserve / lemon / sugar syrup / Orange bitter',
                        descriptionGr: '',
                        id: '149',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Pr. Spirits',
                value: 'Pr. Spirits_007',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Americano - Bitter / Sweet Vermout / Schweppes Soda Water',
                        valueEng: 'Americano',
                        valueGr: '',
                        descriptionEng: 'Bitter / Sweet Vermout / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '150',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Aperol Spritz - Prosecco / Aperitif / Schweppes Soda Water',
                        valueEng: 'Aperol Spritz',
                        valueGr: '',
                        descriptionEng: 'Prosecco / Aperitif / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '151',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Bloody Mary - Vodka  / Tomato Juice  / lemon',
                        valueEng: 'Bloody Mary',
                        valueGr: '',
                        descriptionEng: 'Vodka  / Tomato Juice  / lemon',
                        descriptionGr: '',
                        id: '152',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Boulevardier - Jack Daniels Old No7 / Bitter / Sweet red Vermouth',
                        valueEng: 'Boulevardier',
                        valueGr: '',
                        descriptionEng: 'Jack Daniels Old No7 / Bitter / Sweet red Vermouth',
                        descriptionGr: '',
                        id: '153',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Campari Orange (Garibaldi) - Bitter / Sugar syrup / Orange juice',
                        valueEng: 'Campari Orange (Garibaldi)',
                        valueGr: '',
                        descriptionEng: 'Bitter / Sugar syrup / Orange juice',
                        descriptionGr: '',
                        id: '154',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Campari Soda - Bitter / Schweppes Soda Water  /',
                        valueEng: 'Campari Soda',
                        valueGr: '',
                        descriptionEng: 'Bitter / Schweppes Soda Water  /',
                        descriptionGr: '',
                        id: '155',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Campari Spritz - Prosecco / Bitter / Schweppes Soda Water',
                        valueEng: 'Campari Spritz',
                        valueGr: '',
                        descriptionEng: 'Prosecco / Bitter / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '156',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Campari Tonic - Bitter / Schweppes Indian Tonic Water /',
                        valueEng: 'Campari Tonic',
                        valueGr: '',
                        descriptionEng: 'Bitter / Schweppes Indian Tonic Water /',
                        descriptionGr: '',
                        id: '157',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cosmopolitan - Vodka / Triple sec / Amita Granberry juice',
                        valueEng: 'Cosmopolitan',
                        valueGr: '',
                        descriptionEng: 'Vodka / Triple sec / Amita Granberry juice',
                        descriptionGr: '',
                        id: '158',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Cuba Libre - Dark rum / lime juice / Coca-Cola',
                        valueEng: 'Cuba Libre',
                        valueGr: '',
                        descriptionEng: 'Dark rum / lime juice / Coca-Cola',
                        descriptionGr: '',
                        id: '159',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Daiquiri - White rum / Sugar Syrup / lime',
                        valueEng: 'Daiquiri',
                        valueGr: '',
                        descriptionEng: 'White rum / Sugar Syrup / lime',
                        descriptionGr: '',
                        id: '160',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dark n Stormy - Dark Rum / lime / Three Cents Ginger Beer',
                        valueEng: 'Dark n Stormy',
                        valueGr: '',
                        descriptionEng: 'Dark Rum / lime / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '161',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Lond Island - Rum / Tequila blanco / vodka / gin / Triple sec / lime / Coca-Cola',
                        valueEng: 'Lond Island',
                        valueGr: '',
                        descriptionEng: 'Rum / Tequila blanco / vodka / gin / Triple sec / lime / Coca-Cola',
                        descriptionGr: '',
                        id: '162',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Manhattan - bourbon / Rye whisky / Sweet vermouth',
                        valueEng: 'Manhattan',
                        valueGr: '',
                        descriptionEng: 'bourbon / Rye whisky / Sweet vermouth',
                        descriptionGr: '',
                        id: '163',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Margarita - el Jimador Blanco / Triple sec / lime',
                        valueEng: 'Margarita',
                        valueGr: '',
                        descriptionEng: 'el Jimador Blanco / Triple sec / lime',
                        descriptionGr: '',
                        id: '164',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Dry Martini - Gin / Dry vermouth',
                        valueEng: 'Dry Martini',
                        valueGr: '',
                        descriptionEng: 'Gin / Dry vermouth',
                        descriptionGr: '',
                        id: '165',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Mojito - Rum / Sugar syrup / lime / Schweppes soda',
                        valueEng: 'Mojito',
                        valueGr: '',
                        descriptionEng: 'Rum / Sugar syrup / lime / Schweppes soda',
                        descriptionGr: '',
                        id: '166',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Moscow Mule - Vodka / lime  / Sugar syrup / Three Cents Ginger Beer',
                        valueEng: 'Moscow Mule',
                        valueGr: '',
                        descriptionEng: 'Vodka / lime  / Sugar syrup / Three Cents Ginger Beer',
                        descriptionGr: '',
                        id: '167',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Negroni - Gin / Bitter / Sweet red Vermouth',
                        valueEng: 'Negroni',
                        valueGr: '',
                        descriptionEng: 'Gin / Bitter / Sweet red Vermouth',
                        descriptionGr: '',
                        id: '168',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Old Fashioned - Jack Daniels Old No7 / Sugar syrup / Angostura bitter',
                        valueEng: 'Old Fashioned',
                        valueGr: '',
                        descriptionEng: 'Jack Daniels Old No7 / Sugar syrup / Angostura bitter',
                        descriptionGr: '',
                        id: '169',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Pina Colada - Rum white / Coconut creme / lime / Amita Pinapple',
                        valueEng: 'Pina Colada',
                        valueGr: '',
                        descriptionEng: 'Rum white / Coconut creme / lime / Amita Pinapple',
                        descriptionGr: '',
                        id: '170',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Tom Collins - Old tom gin / lemon / Sugar syrup / Schweppes Soda Water',
                        valueEng: 'Tom Collins',
                        valueGr: '',
                        descriptionEng: 'Old tom gin / lemon / Sugar syrup / Schweppes Soda Water',
                        descriptionGr: '',
                        id: '171',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Whiskey Sour - Whiskey bourbon / lemon / sugar syrup / Orange bitter',
                        valueEng: 'Whiskey Sour',
                        valueGr: '',
                        descriptionEng: 'Whiskey bourbon / lemon / sugar syrup / Orange bitter',
                        descriptionGr: '',
                        id: '172',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
        ],
    },
]