import React from 'react'

const Variation5 = ({ state, styles }) => {

    {/* BLACK 05 */ }
    return (
        <div className='menu-item-wrapper realtive w-full flex gap-x-4 justify-between'>
            <div className='block-1 relative'
            style={{
                maxWidth: '140px'
            }}>
                <div className='menu-item-title text-left'
                    style={{
                        fontSize: `${styles.titleFontSize}`,
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.titleColor}`,
                        display: 'block'
                    }}> {state.valueEng} </div>
                <div className='menu-item-description float-left text-left'
                    style={{
                        fontSize: `${styles.subtitleFontSize}`,
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.subtitleColor}`
                    }}> {state.descriptionEng} </div>
            </div>
            <div className='block-2 relative min-w-[30px] float-right text-right mt-auto'>
                <div className='menu-item-price'
                    style={{
                        fontSize: `${styles.priceFontSize}`,
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.priceColor}`
                    }}>{state.price ? state.price + ' €' : ' €'}</div>
            </div>
        </div>
    )
}

export default Variation5