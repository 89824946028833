import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axios';


// Get all orders
export const fetchTemplates = createAsyncThunk(
    '/templates',
    async () => {
        const { data } = await axios.get('/templates')
        const res = data.map((obj) => {
            return obj
        })
        return res;
    },
);

export const fetchTemplate = createAsyncThunk(
    '/templates/:id',
    async (id) => {
        try {
            const { data } = await axios.get(`/templates/${id}`)
            return data;
        } catch (err) {
            console.log(err)
        }
    },
);

const initialState = {
    template: [],
    templates: null,
    status: 'loading',
    loading: 'loading'
};

const templateSlice = createSlice({
    name: 'Template',
    initialState,
    reducers: {

        // Add menu
        addTemplateMenu: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        idx === action.payload.page ?
                            item.map((page) => ({
                                ...page,
                                items: [...page.items, action.payload.data],
                            }))
                            : item
                    ))
                }
            }
        },

        // Menu order
        addTemplateMenuOrder: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        idx === action.payload.page ?
                            item.map((page) => ({
                                ...page,
                                items: action.payload.data,
                            }))
                            : item
                    ))
                }
            }
        },

        // Add menu price
        addTemplateMenuPrice: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        idx === action.payload.page ?
                            item.map((page) => ({
                                ...page,
                                items: page.items.map((res) =>
                                    res.id === action.payload.id
                                        ? {
                                            ...res,
                                            price: action.payload.price
                                        }
                                        : res)
                            }))
                            : item
                    ))
                }
            }
        },

        // Remove menu by Id
        removeTemplateMenu: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        idx === action.payload.page ?
                            item.map((page) => ({
                                ...page,
                                items: page.items.filter((item) => (item.id !== action.payload.id))
                            }))
                            : item
                    ))
                }
            }
        },

        // Add menu combo
        addTemplateMenuCombo: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        item.map((page) => ({
                            ...page,
                            items: [...page.items, action.payload.data],
                        }))
                    ))
                }
            }
        },

        // Remove menu combo
        removeTemplateMenuCombo: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        item.map((page) => ({
                            ...page,
                            items: page.items.filter((item) => (item.id !== action.payload.id))
                        }))
                    ))
                }
            }
        },


        // Add menu price
        addTemplateMenuComboPrice: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        item.map((page) => ({
                            ...page,
                            price: action.payload.price
                        }))
                    ))
                }
            }
        },

        // Add menu price
        addTemplateMenuComboOfferPrice: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        item.map((page) => ({
                            ...page,
                            priceOffer: action.payload.priceOffer
                        }))
                    ))
                }
            }
        },

        // Add menu price
        addTemplateMenuComboDescription: (state, action) => {
            return {
                ...state,
                template: {
                    ...state.template,
                    pages: state.template.pages.map((item, idx) => (
                        item.map((page) => ({
                            ...page,
                            description: action.payload.description
                        }))
                    ))
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // Get all templates
            .addCase(fetchTemplates.pending, (state) => {
                state.status = 'loading';
                state.templates = null;
            })
            .addCase(fetchTemplates.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.templates = action.payload;
            })
            .addCase(fetchTemplates.rejected, (state, action) => {
                state.status = 'loading';
                state.templates = null;
            })
            // Get template by ID
            .addCase(fetchTemplate.pending, (state) => {
                state.loading = 'loading';
                state.template = null;
            })
            .addCase(fetchTemplate.fulfilled, (state, action) => {
                state.loading = 'loaded';
                state.template = action.payload;
            })
            .addCase(fetchTemplate.rejected, (state, action) => {
                state.loading = 'loading';
                state.template = null;
            }) 
    }
});

export const {
    addTemplateMenu,
    addTemplateMenuOrder,
    addTemplateMenuPrice,
    removeTemplateMenu,
    addTemplateMenuCombo,
    removeTemplateMenuCombo,
    addTemplateMenuComboPrice,
    addTemplateMenuComboOfferPrice,
    addTemplateMenuComboDescription
} = templateSlice.actions

export const templateReducer = templateSlice.reducer;
