import React from 'react'
import Sidebar from '../../components/Dashboard/sidebar'
import { Outlet } from 'react-router-dom'
import Orders from './orders'

const Dashboard = () => {

  return (
    <div className="flex">
      <div className="h-screen">
        <Sidebar />
      </div>
      <div className="bg-gray-100 h-screen p-3 flex-auto">
        <Outlet />
      </div>
    </div>
  )
}

export default React.memo(Dashboard)