import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    items: [],
    coctailsItems: [],
    menuItems: [],
    signature: false
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        addMenuItem: (state, action) => {
            state.items.push(action.payload)
        },
        removeMenuItem: (state, action) => {
            state.items = state.items.filter((item) => item.id !== action.payload)
        },
        addCustomPrice: (state, action) => {
            return {
                ...state,
                items: state.items.map(
                    obj => obj.id === action.payload.id
                        ? {
                            ...obj,
                            price: action.payload.price
                        }
                        : obj
                )
            }
        },
        addCoctailsItems: (state, action) => {
            //state.coctailsItems.pop();
            state.coctailsItems = action.payload
        },
        addCoctailPrice: (state, action) => {
            return {
                ...state,
                coctailsItems: state.coctailsItems.map((item) =>
                    item.id === action.payload.id
                        ? {
                            ...item,
                            price: action.payload.price
                        }
                        : item
                )
            }
        },

        addMenuItems: (state, action) => {
            //state.coctailsItems.pop();
            state.menuItems = action.payload
        },
        addMenuPrice: (state, action) => {
            return {
                ...state,
                menuItems: state.menuItems.map((item) =>
                    item.id === action.payload.id
                        ? {
                            ...item,
                            price: action.payload.price
                        }
                        : item
                )
            }
        },

        addSignature: (state) => {
            state.signature = !state.signature;
        },
        resetSignature: (state) => {
            state.signature = false;
        },
    }
})

export const { addMenuItem, removeMenuItem, addCoctailsItems, addCoctailPrice, addCustomPrice, addSignature, resetSignature, addMenuItems, addMenuPrice } = menuSlice.actions
export const menuReducer = menuSlice.reducer