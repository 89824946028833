import React from 'react'

import Topbar from '../../components/Template/topbar'
import Preview from '../../components/Template/preview'
import StepperComponent from '../../components/Template/stepper'
import Helper from '../../components/Template/helper'

const Template = () => {

    return (
        <div className="flex  font-sans text-gray-900">
            <div className="flex h-screen flex-1 flex-col">
                <Topbar />
                <main className="flex-1 overflow-y-scroll overflow-x-hidden relative">
                    <div className="fixed h-full w-full bg-white -z-50"><div className="absolute h-full w-full bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)]"></div></div>
                    <div className="my-12">
                        <Preview />
                    </div>
                    <div className="fixed left-16 bottom-5">
                        <Helper />
                    </div>
                </main>
            </div>
            <StepperComponent />
        </div>
    )
}

export default Template