import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import Select from 'react-select';
import { Button, ButtonGroup, Checkbox, Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from './../../../../lib/features/helper/counterSlice'
import { UploadFile, pageNav, changeStatus } from './../../../../lib/features/layout/page';

import Loader from './../../../../components/Layout/loader'

import { options } from './../../../../data/approvers'

import axios from './../../../../axios'

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const style = {
    control: (base) => ({
        ...base,
        border: '1px solid #b0bec5',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #b0bec5',
        }
    })
};

const SendOrder = (props, ref) => {

    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const template = useSelector((state) => state.template.template)
    const count = useSelector((state) => state.counter.value)
    const file = useSelector((state) => state.page.file)
    const pdf = useSelector((state) => state.page.pdf)
    const loadingFile = useSelector((state) => state.page.loadingFile)
    const user = useSelector((state) => state.auth.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(pageNav(true))
    }, [])

    const [formData, setFormData] = useState({
        StoreTradeName: '',
        CustomerName: '',
        CustomerCode: '',
        Phone: '',
        Address: '',
        PostCode: '',
        City: '',
        NameReceivingOfficer: '',
        Comment: '',
        PDF: '',
        AttachFile: file,
        Quantity: '',
        Seller: user
    })

    useEffect(() => {
        if (loadingFile === 'loaded') {
            (async () => {
                await axios.post('/orders', { ...formData, PDF: pdf.data.url })
            })()
            handleOpen()
            dispatch(changeStatus())
        }
    }, [loadingFile])

    useEffect(() => {
        (async () => {
            setFormData({ ...formData, Quantity: count })
        })()
    }, [count])

    const renderPDF = async () => {
        const input = document.getElementById("divToPrint");
        html2canvas(input)
            .then((canvas) => {

                var padding = 0;
                if (template.type === 'cocktail') { padding = 60 }
                if (template.type === 'combo') { padding = 20 }

                var imgWidth = template.currentSize.w;
                var pageHeight = template.currentSize.h;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;

                var doc = new jsPDF('portrait', 'px', [template.currentSize.w, template.currentSize.h], 'MEDIUM');
                var position = 0;
                var imgData = canvas.toDataURL("image/jpeg", 1);
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + padding);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + padding);
                    heightLeft -= pageHeight;
                }

                const load = async () => {
                    try {
                        const newName = `${Math.floor(Date.now() / 1000)}.pdf`
                        const pdf = new File([doc.output('blob')], newName, {
                            type: doc.output('blob').type,
                            lastModified: doc.output('blob').lastModified,
                        });
                        const formData = new FormData();
                        formData.append("image", pdf);
                        await dispatch(UploadFile(formData))
                    } catch (err) {
                        console.warn(err);
                        alert('Error upload file')
                    }
                }
                load()

            })
    }

    useImperativeHandle(ref, () => ({ sendDataForm }));
    const sendDataForm = () => {
        if (
            formData.StoreTradeName !== '',
            formData.CustomerName !== '',
            formData.CustomerCode !== '',
            formData.Phone !== '',
            formData.Address !== '',
            formData.PostCode !== '',
            formData.City !== '',
            formData.NameReceivingOfficer !== ''
        ) {
            renderPDF()
        } else {
            alert('Παρακαλώ συμπληρώστε την φόρμα')
        }
    };

    if (loadingFile === 'loading') {
        return <Loader />
    }

    return (
        <>
            <div className="text-2xl font-semibold py-2">Αποστολή εκτύπωσης</div>
            <div
                className="max-w-screen-lg mt-8 mb-2 w-100 sm:w-100">
                <div className="flex flex-col gap-6 mb-1">
                    <h6
                        className="block -mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                        Εμπορική Επωνυμία Καταστήματος
                    </h6>
                    <div className="relative h-11 w-full min-w-[200px]">
                        <input
                            required
                            name="StoreTradeName"
                            onChange={(e) => setFormData({ ...formData, StoreTradeName: e.target.value })}
                            className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                        <label
                            className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                    </div>

                    <div className="flex justify-between gap-x-4 mb-1">
                        <div className="w-full">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Επωνυμία Πελάτη
                            </h6>
                            <div className="relative h-11 w-full min-w-[200px]">
                                <input
                                    required
                                    name="CustomerName"
                                    onChange={(e) => setFormData({ ...formData, CustomerName: e.target.value })}
                                    className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                <label
                                    className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                            </div>
                        </div>
                        <div className="w-full">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Κωδικός Πελάτη
                            </h6>
                            <div className="relative h-11 w-full min-w-[200px]">
                                <input
                                    required
                                    name="CustomerCode"
                                    onChange={(e) => setFormData({ ...formData, CustomerCode: e.target.value })}
                                    className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                <label
                                    className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between gap-x-4 mb-1">
                        <div className="w-full">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Τηλέφωνο
                            </h6>
                            <div className="relative h-11 w-full min-w-[200px]">
                                <input
                                    required
                                    name="Phone"
                                    onChange={(e) => setFormData({ ...formData, Phone: e.target.value })}
                                    className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                <label
                                    className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                            </div>
                        </div>
                        <div className="w-full">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Διεύθυνση
                            </h6>
                            <div className="relative h-11 w-full min-w-[200px]">
                                <input
                                    required
                                    name="Address"
                                    onChange={(e) => setFormData({ ...formData, Address: e.target.value })}
                                    className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                <label
                                    className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between mb-1 gap-x-4">
                        <div className="w-full">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Ταχυδρομικός κώδικας
                            </h6>
                            <div className="relative h-11 w-full min-w-[200px]">
                                <input
                                    required
                                    name="PostCode"
                                    onChange={(e) => setFormData({ ...formData, PostCode: e.target.value })}
                                    className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                <label
                                    className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                            </div>
                        </div>
                        <div className="w-full">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Πόλη
                            </h6>
                            <div className="relative h-11 w-full min-w-[200px]">
                                <input
                                    required
                                    name="City"
                                    onChange={(e) => setFormData({ ...formData, City: e.target.value })}
                                    className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                <label
                                    className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                            </div>
                        </div>
                    </div>
                    <h6
                        className="block -mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                        Ονοματεπώνυμο Υπεύθυνου Παραλαβής
                    </h6>
                    <div className="relative h-11 w-full min-w-[200px]">
                        <input
                            required
                            name="NameReceivingOfficer"
                            onChange={(e) => setFormData({ ...formData, NameReceivingOfficer: e.target.value })}
                            className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                        <label
                            className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                    </div>
                    <div className="flex justify-between mb-1">
                        <div className="w=1/2">
                            <h6
                                className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                Τεμάχια
                            </h6>
                            <div className="flex flex-row gap-x-2">
                                <ButtonGroup variant="outlined">
                                    <Button onClick={() => dispatch(decrement())}><IoIosArrowBack className="h-4 w-4" /></Button>
                                    <Button className="font-light">{count}</Button>
                                    <Button onClick={() => dispatch(increment())}><IoIosArrowForward className="h-4 w-4" /></Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <h6
                        className="block -mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                        Approvers
                    </h6>
                    <Select
                        placeholder={<div className='flex items-center'></div>}
                        noOptionsMessage={() => 'No DATA'}
                        options={options}
                        closeMenuOnSelect={true}
                        styles={style}
                        onChange={(selectedOption) => setFormData({ ...formData, Approvers: selectedOption })}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                    />
                    <h6
                        className="block -mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                        Σχόλια
                    </h6>
                    <div className="w-100">
                        <textarea
                            name="Comment"
                            onChange={(e) => setFormData({ ...formData, Comment: e.target.value })}
                            className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        />
                    </div>

                    {template.type === 'cocktail' ?
                        (<Checkbox label="Αποστολή για ψηφιακή προβολή" />)
                        : ''}
                </div>
            </div>

            <Dialog open={open} handler={handleOpen} size='xs' className="p-4">
                <DialogBody>
                    <div className="text-xl mb-2">
                        Το έντυπο έχει δημιουργηθεί και έχει σταλεί <br /> προς έγκριση για εκτύπωση.
                    </div>
                    <div className="text-xl mb-5">
                        Μπορείτε να δείτε προεπισκόπηση του εντύπου κατεβάζοντάς το.
                    </div>
                    <div className="text-red-500">Προσοχή: </div>Το αρχείο δεν είναι προς εκτύπωση.
                </DialogBody>
                <DialogFooter className="gap-x-4 justify-center">
                    <Button
                        onClick={() => navigate('/')} variant="filled">
                        <span className="capitalize font-normal">Κλείσιμο</span>
                    </Button>
                    <a href={pdf !== '' ? pdf.data.url : ''} target="_blank" rel="noopener noreferrer" download>
                        <Button
                            variant="outlined"
                        >
                            <span className="capitalize font-normal">Λήψη αρχείου</span>
                        </Button>
                    </a>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default forwardRef(SendOrder)