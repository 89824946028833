export const options = [
    { email: 'pavloffedogmail.com', label: 'E4Z', value: 'E4Z' },
    { email: 'pavloffedogmail.com', label: 'E4D', value: 'E4D' },
    { email: 'pavloffedogmail.com', label: 'E5T', value: 'E5T' },
    { email: 'pavloffedogmail.com', label: 'E4W', value: 'E4W' },
    { email: 'pavloffedogmail.com', label: 'E5Y', value: 'E5Y' },
    { email: 'pavloffedogmail.com', label: 'E4V', value: 'E4V' },
    { email: 'pavloffedogmail.com', label: 'E5W', value: 'E5W' },
    { email: 'pavloffedogmail.com', label: 'E5Z', value: 'E5Z' },
    { email: 'pavloffedogmail.com', label: 'E5U', value: 'E5U' },
    { email: 'pavloffedogmail.com', label: 'E9T', value: 'E9T' },
    { email: 'pavloffedogmail.com', label: 'E5V', value: 'E5V' },
    { email: 'pavloffedogmail.com', label: 'E4U', value: 'E4U' },
    { email: 'pavloffedogmail.com', label: 'E4X', value: 'E4X' },
    { email: 'pavloffedogmail.com', label: 'E4I', value: 'E4I' },
    { email: 'pavloffedogmail.com', label: 'E4G', value: 'E4G' },
    { email: 'pavloffedogmail.com', label: 'E5X', value: 'E5X' },
    { email: 'pavloffedogmail.com', label: 'E4Y', value: 'E4Y' },

    { email: 'approver@host.com', label: 'E2Z', value: 'E2Z' },
    { email: 'approver@host.com', label: 'E2Y', value: 'E2Y' },
    { email: 'approver@host.com', label: 'E2V', value: 'E2V' },
    { email: 'approver@host.com', label: 'E2W', value: 'E2W' },
    { email: 'approver@host.com', label: 'E2X', value: 'E2X' },
    { email: 'approver@host.com', label: 'E3Z', value: 'E3Z' },
    { email: 'approver@host.com', label: 'E3X', value: 'E3X' },
    { email: 'approver@host.com', label: 'E3V', value: 'E3V' },
    { email: 'approver@host.com', label: 'E3W', value: 'E3W' },
    { email: 'approver@host.com', label: 'E3Y', value: 'E3Y' },
    { email: 'approver@host.com', label: 'E6R', value: 'E6R' },
    { email: 'approver@host.com', label: 'E9R', value: 'E9R' },
    { email: 'approver@host.com', label: 'E7R', value: 'E7R' },
    { email: 'approver@host.com', label: 'E4T', value: 'E4T' },
    { email: 'approver@host.com', label: 'E9H', value: 'E9H' },

    { email: 'user@host.com', label: 'E9Z', value: 'E9Z' },
    { email: 'user@host.com', label: 'E1Y', value: 'E1Y' },
    { email: 'user@host.com', label: 'E1X', value: 'E1X' },
    { email: 'user@host.com', label: 'E1V', value: 'E1V' },
    { email: 'user@host.com', label: 'E1T', value: 'E1T' },
    { email: 'user@host.com', label: 'E1Z', value: 'E1Z' },
    { email: 'user@host.com', label: 'E1W', value: 'E1W' },
    { email: 'user@host.com', label: 'E1U', value: 'E1U' },
    { email: 'user@host.com', label: 'E9Y', value: 'E9Y' },
    { email: 'user@host.com', label: 'E9C', value: 'E9C' },
    { email: 'user@host.com', label: 'E9P', value: 'E9P' },
    { email: 'user@host.com', label: 'E5H', value: 'E5H' },
    { email: 'user@host.com', label: 'E3J', value: 'E3J' },
    { email: 'user@host.com', label: 'E7H', value: 'E7H' },
    { email: 'user@host.com', label: 'E6H', value: 'E6H' },
    { email: 'user@host.com', label: 'E4F', value: 'E4F' },
    { email: 'user@host.com', label: 'E4E', value: 'E4E' },
]