import React from 'react'

import { Link, useNavigate } from 'react-router-dom'
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  Chip,
  IconButton,
  Tooltip,
  Button
} from "@material-tailwind/react";

import { FaRegTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

import Pagination from "./../../../components/Dashboard/pagination/";
import Search from "./../../../components/Dashboard/search/";

const TABLE_HEAD = ["Κωδικος πωλητη", "Ονοματεπώνυμο", "Email", "Τηλέφωνο", "Περιοχή", "Κατάσταση", ""];

const TABLE_ROWS = [
  {
    _id: "ID12654",
    name: "",
    surname: "Doe",
    email: "JohnUser01@host.com",
    phone: "1234567890",
    location: "Thessaloniki",
    status: "complete",
  },
];

const Users = () => {

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 pt-1 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="blue-gray">
              Πωλητές
            </Typography>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <Search />
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(
              (
                {
                  _id,
                  name,
                  surname,
                  email,
                  phone,
                  location,
                  status,
                },
                index,
              ) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={name}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {_id}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                        >
                          {name}{surname}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {email}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {phone}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {location}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={status}
                          color={
                            status === "complete"
                              ? "green"
                              : status === "pending"
                                ? "amber"
                                : "red"
                          }
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className='flex gap-x-4 float-right'>
                        <Tooltip content="Edit User">
                          <Link to="/dashboard/orders/123">
                            <Button variant="outlined" size="sm" className="capitalize">Προβολή</Button>
                          </Link>
                        </Tooltip>
                        <Tooltip content="Delete User">
                          <Button color="red" size="sm" className="capitalize">Ακύρωση</Button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default Users