import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1,
}

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      if (state.value > 1) {
        state.value -= 1
      }
    },
    reset: () => initialState
  },
})

export const { increment, decrement, reset } = counterSlice.actions
export const counterReducer = counterSlice.reducer