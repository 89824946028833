import React from "react";
import { Link, Navigate } from "react-router-dom"
import {
    Card,
    Button,
    Typography,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form"

import { useDispatch, useSelector } from 'react-redux'

import login_img from '../../assets/login.jpg'
import login_logo from '../../assets/logo.png'
import { fetchLogin, selectIsAuth } from "../../lib/features/user/userSlice";

import { motion } from "framer-motion";

const Login = () => {

    const dispatch = useDispatch()

    const isAuth = useSelector(selectIsAuth)
    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: {
            email: '',
            password: ''
        }
    })

    const onSubmit = async (values) => {
        const data = await dispatch(fetchLogin(values));

        if (!data.payload) {
            return toast.error('Το προφιλ σας δεν είναι ενεργοποιημένο', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                progress: undefined,
                theme: "light",
            });
        }

        if ('token' in data.payload) {
            window.localStorage.setItem('token', data.payload.token);
        }
    }

    if (isAuth && isAuth.isActivated) {
        return <Navigate to='/home' />
    }

    return (
        <motion.main
            className="main__container"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ x: "100%", opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="flex justify-center items-center h-screen">

                <div className="w-1/2 h-screen hidden lg:block">
                    <img src={login_img} alt="img" className="object-cover w-full h-full" />
                </div>

                <div className="flex justify-center lg:p-36 md:p-52 sm:20 p-8 w-full lg:w-1/2">
                    <Card color="transparent" shadow={false}>
                        <div className="flex justify-center mb-14">
                            <img src={login_logo} alt="img" className="object-cover h-32" />
                        </div>
                        <div className="text-3xl font-semibold text-center">Είσοδος</div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="mt-8 mb-2 w-90 max-w-screen-lg sm:w-96">
                            <div className="mb-1 flex flex-col gap-4">
                                <div className="relative w-full min-w-[200px] h-10">
                                    <input
                                        {...register('email', { required: 'Please enter email' })}
                                        type="email"
                                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                                        placeholder=" " />
                                    <label
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Email
                                    </label>
                                </div>
                                <div className="relative w-full min-w-[200px] h-10">
                                    <input
                                        {...register('password', { required: 'Please enter password' })}
                                        type="password"
                                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                                        placeholder=" " />
                                    <label
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">Password
                                    </label>
                                </div>
                            </div>
                            <Button
                                disabled={!isValid}
                                className="mt-6 capitalize" type="submit" fullWidth>
                                Σύνδεση
                            </Button>
                        </form>
                        <Typography color="gray" className="mt-4 text-center font-normal">
                            Δεν έχετε λογαριασμό;{" "}
                            <Link to="/register" className="font-medium text-gray-900">
                                Εγγραφή
                            </Link>
                        </Typography>
                    </Card>
                </div>
            </div>
        </motion.main>
    )
}

export default React.memo(Login)