import logo_01 from './../assets/logos/logo_01.png'
import logo_02 from './../assets/logos/logo_02.png'
import logo_03 from './../assets/logos/logo_03.png'
import logo_04 from './../assets/logos/logo_04.png'
import logo_05 from './../assets/logos/logo_05.png'
import logo_06 from './../assets/logos/logo_06.png'
import logo_07 from './../assets/logos/logo_07.png'
import logo_08 from './../assets/logos/logo_08.png'
import logo_09 from './../assets/logos/logo_09.png'
import logo_10 from './../assets/logos/logo_10.png'
import logo_11 from './../assets/logos/logo_11.png'
import logo_12 from './../assets/logos/logo_12.png'

export const logos = [
    { value: 'No Brand', logo: ''},
    { value: '1888', logo: logo_01 },
    { value: 'dekaraki', logo: logo_02 },
    { value: 'el Jimador', logo: logo_03 },
    { value: 'FG', logo: logo_04 },
    { value: 'GinMare', logo: logo_05 },
    { value: 'Herradura', logo: logo_06 },
    { value: 'Jack Daniels', logo: logo_07 },
    { value: 'Macallan', logo: logo_08 },
    { value: 'NM', logo: logo_09 },
    { value: 'PLOMARI', logo: logo_10 },
    { value: 'TFG', logo: logo_11 },
    { value: 'ThreeCents', logo: logo_12 }
]