import React from 'react'
import { Link } from "react-router-dom";

import { fsr_logos } from './../../../data/fsr_logos'

import { IoInformationCircleOutline } from "react-icons/io5";

const TemplateQSR = ({ category }) => {
    return (
        <div class="container my-4 mx-auto">
            <div className='flex gap-x-2 items-center bg-red-500 bg-opacity-60 text-white rounded-lg px-4 mb-5'>
                <IoInformationCircleOutline className='h-7 w-7' />
                <div className='my-4 text-xl font-semibold'>FSA's</div>
            </div>
            <div class="flex flex-wrap -mx-1 lg:-mx-4">
                {fsr_logos.map((logo, idx) => (
                    <div key={idx} class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <article class="overflow-hidden rounded-lg shadow-lg">
                            <Link to={`${logo.link}`}>
                                <img class="block h-auto w-full" style={{ background: '#e0e0e0' }} src={logo.logo} alt="card" />
                            </Link>
                        </article>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(TemplateQSR)