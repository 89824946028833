import React from 'react'

import TemplatesPreview from './TemplatePreview';
import TemplateStepper from './TemplateStepper';
import TemplateQSR from './TemplateQSR';

const Templates = ({ category }) => {

    const TemplateSelector = () => {
        if (category === 'catalog') { return <TemplateStepper category={category} /> }
        if (category === 'cocktail') { return <TemplatesPreview category={category} /> }
        if (category === 'combo') { return <TemplatesPreview category={category} /> }
        if (category === 'qsr') { return <TemplateQSR category={category} /> }
    }

    return (
        <div style={{ minHeight: '650px' }}>
            {TemplateSelector()}
        </div>

    )
}

export default React.memo(Templates)