import React, { useState } from 'react'
import { Link } from "react-router-dom";
import {
    Typography,
    Card,
    CardBody,
    Dialog,
    DialogBody
} from "@material-tailwind/react";

import { FaRegListAlt } from "react-icons/fa";

const Fsr = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    return (
        <>
            <div className="grid grid-cols-3 grid-rows-1 gap-4 p-4">
                <div>
                    <Card className="mt-6 w-100">
                        <div color="blue-gray" className="relative h-56 flex justify-center flex-col m-auto">
                            <FaRegListAlt className='text-9xl' />
                        </div>
                        <CardBody className='text-center'>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                ΚΑΤΑΛΟΓΟΙ
                            </Typography>
                        </CardBody>
                    </Card>
                </div>
            </div>

            <Dialog open={open} handler={handleOpen} size="xl" className='overflow-scroll max-h-[90vh]'>
                <DialogBody>
                    {/* <Templates category={category} /> */}
                </DialogBody>
            </Dialog>
        </>
    )
}

export default Fsr