import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Avatar } from "@material-tailwind/react";
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../../lib/features/user/userSlice';
import Loader from '../../Layout/loader';
import login_logo from '../../../assets/logo.png'

const TopbarHome = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.data)
    const status = useSelector((state) => state.auth.status)

    const handleLogout = () => {
        if (window.confirm('Are sure you want to logout')) {
            dispatch(logout());
            window.localStorage.removeItem('token');
        }
    }

    if (status === 'loading') {
        return <Loader />
    }

    return (
        <header className="flex h-18 items-center justify-between gap-x-6 bg-white px-12 py-4">
            <div className="flex items-center gap-x-5">
                <img src={login_logo} alt="img" className="object-cover h-12" />
            </div>
            <div className="flex gap-x-10 h-10">
                <div className="flex items-center gap-x-6">
                    <div className="flex gap-x-2 items-center">
                        <Avatar withBorder={true} className="p-0.5" size="sm" src="https://avatars.githubusercontent.com/u/117155218?v=4" alt="avatar" />
                        <div>
                            <div className="text-sm font-semibold leading-2">
                                {user.name}  {user.surname}
                            </div>
                            <div className="text-sm font-light">
                                {user.email}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-4">
                    <Button
                        onClick={() => navigate('/dashboard/report')}
                        variant="outlined"
                        className='capitalize'>Πίνακας Ελέγχου</Button>
                    <Button
                        onClick={handleLogout}
                        variant="filled"
                        className='capitalize'>Αποσύνδεση</Button>
                </div>
            </div>
        </header>
    )
}

export default React.memo(TopbarHome)