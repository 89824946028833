import React, { useState, useRef } from "react";
import { Stepper, Step, Button } from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux';
import { forward, currStepForward, currStepBackward, pageNav } from '../../../lib/features/layout/page';

import SendOrder from "./sendOrder";
import ProductsOffer from "./productsOffer";
import PriceOffer from "./priceOffer";

const StepperCombo = () => {
    const dispatch = useDispatch();
    const page = useSelector((state) => state.page)

    const [activeStep, setActiveStep] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);

    const handleNext = () => {
        !isLastStep && setActiveStep((cur) => cur + 1);
        if (page.current === 0) {
            dispatch(forward());
        }
        dispatch(currStepForward())

        if (page.current === 5) {
            dispatch(pageNav(true))
        }
        if (page.current !== 5) {
            dispatch(pageNav(false))
        }
    }

    const handlePrev = () => {
        !isFirstStep && setActiveStep((cur) => cur - 1);
        dispatch(currStepBackward())

        if (page.current === 5) {
            dispatch(pageNav(true))
        }
        if (page.current !== 5) {
            dispatch(pageNav(false))
        }
    }

    const renderStepper = () => {
        if (activeStep === 0) { return <ProductsOffer /> }
        if (activeStep === 1) { return <PriceOffer /> }
        if (activeStep === 2) { return <SendOrder ref={childRef} /> }
    }

    const childRef = useRef(null);
    const sendDataForm = () => {
        if (childRef.current) {
            childRef.current.sendDataForm();
        }
    };

    return (
        <aside className="relative flex h-screen w-[500px] flex-col  bg-white border border-l">
            <div className="w-full py-4 px-6 relative">
                <Stepper
                    activeStep={activeStep}
                    isLastStep={(value) => setIsLastStep(value)}
                    isFirstStep={(value) => setIsFirstStep(value)}
                >
                    <Step onClick={() => setActiveStep(0)} className="rounded-xl pointer-events-none">1</Step>
                    <Step onClick={() => setActiveStep(1)} className="rounded-xl pointer-events-none">2</Step>
                    <Step onClick={() => setActiveStep(2)} className="rounded-xl pointer-events-none">3</Step>
                </Stepper>
            </div>
            <div className="flex flex-1 flex-col overflow-y-scroll px-6 py-4 ">
                {renderStepper()}
            </div>
            <div className="flex h-18 items-center gap-x-4 px-6 py-4">
                <Button onClick={handlePrev} disabled={isFirstStep} variant="outlined" fullWidth className="capitalize text-md">
                    Προηγούμενο
                </Button>
                {activeStep < 2 ? (
                    <Button onClick={handleNext} disabled={isLastStep} fullWidth className="capitalize text-md">
                        Επόμενο
                    </Button>
                ) : ''}
                {activeStep === 2 ? (
                    <Button onClick={sendDataForm} fullWidth className="capitalize text-md">
                        Αποστολή
                    </Button>
                ) : ''}
            </div>
        </aside>
    )
}

export default StepperCombo