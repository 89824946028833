import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setZoom } from '../../../../lib/features/layout/zoomSlice';

import ClientLogoPreview from '../cocktailsPages/clientLogo/'
import BrandLogoPreview from '../cocktailsPages/brandLogo/'
import CoctailMenuItem from '../cocktailsPages/menuItem/coctailMenuItem'

import ScrollDown from './../../../Layout/scrollDown'

import PanZoom, { Element } from '@sasza/react-panzoom'
const CocktailsPages = () => {

    const dispatch = useDispatch();

    const align = useSelector((state) => state.page.align)
    const template = useSelector((state) => state.template.template)

    useEffect(() => {
        dispatch(setZoom(45))
    }, [])

    return (
        <>
            <div id="divToPrint" >
                {template.pages.map((page, idx) => (
                    <div style={{ padding: '20px' }}>
                        {
                            idx === 0 ?
                                (
                                    page.map((obj, idx) => (
                                        <div
                                            key={idx}
                                            className="relative"
                                            style={{
                                                width: `${template.currentSize.w}px`,
                                                height: `${template.currentSize.h}px`,
                                                backgroundImage: `url(${obj.cover})`,
                                                position: 'relative',
                                            }}>
                                            <ClientLogoPreview styles={obj.styles} />
                                        </div>))
                                ) :
                                (
                                    page.map((obj, idx) => (
                                        <div
                                            key={idx}
                                            className="relative"
                                            style={{
                                                width: `${template.currentSize.w}px`,
                                                height: `${template.currentSize.h}px`,
                                                backgroundImage: `url(${obj.cover})`,
                                                position: 'relative',
                                            }}>

                                            <>
                                                <div
                                                    className='absolute mx-auto'
                                                    style={{
                                                        top: `${obj.styles.itemsTop}`,
                                                        width: `${obj.styles.itemsTop}`,
                                                        minWidth: `${obj.styles.itemsMinWidth}`,
                                                        left: `${obj.styles.itemsLeft}`,
                                                        right: `${obj.styles.itemsRight}`,
                                                        textAlign: `${align}`
                                                    }}>
                                                    {obj.items.map((item) => (
                                                        <CoctailMenuItem
                                                            key={idx}
                                                            state={item}
                                                            styles={obj.styles}
                                                            template={template}
                                                        />
                                                    ))}
                                                </div>
                                                <BrandLogoPreview styles={obj.styles} />
                                                {template.type === 'cocktail' ?
                                                    <div style={{
                                                        position: 'absolute',
                                                        bottom: '12px',
                                                        right: '16px',
                                                        fontSize: '8px',
                                                        color: obj.styles.bottomTextColor
                                                    }}>
                                                        Απολαύστε Υπεύθυνα
                                                    </div>
                                                    : ''}
                                            </>
                                        </div>
                                    ))
                                )
                        }
                    </div>
                ))}
            </div>
            {/* <ScrollDown /> */}
        </>
    )
}

export default CocktailsPages