import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addTemplateMenu, removeTemplateMenu } from '../../../../lib/features/template/templateSlice';

import { data } from './../../../../data/list'

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

const CocktailsList = () => {

    const dispatch = useDispatch();
    const page = useSelector((state) => state.page.current)

    const onChange = (currentNode, selectedNodes) => {
        const category = 'Drink'
        const req = JSON.parse(JSON.stringify({ ...currentNode, category: category }))
        dispatch(addTemplateMenu({ data: req, page: page, id: currentNode.id }))
    }

    return (
        <DropdownTreeSelect
            data={data}
            onChange={onChange}
            showDropdown={'initial'}
            texts={{ placeholder: 'Search' }}
            mode="hierarchical"
        />
    )
}

export default React.memo(CocktailsList)