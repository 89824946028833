import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://printit-app-73bf3b59ee9c.herokuapp.com/',
    // baseURL: 'http://localhost:3001/',
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = window.localStorage.getItem('token');
    return config;
});

export default instance;