export const data = [
    {
        label: 'SSD’s',
        value: 'ssds_01',
        className: 'title-menu-list notouch',
        children: [
            {
                label: 'Αναψυκτικά',
                value: 'Anapsyktika',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Coca-Cola - Zero, Regular, Zero χωρίς καφεΐνη, Light 250ml',
                        valueEng: 'Coca-Cola',
                        valueGr: 'Coca-Cola',
                        descriptionEng: 'Zero, Regular, Zero sugar Zero caffeine, Light 250ml',
                        descriptionGr: 'Zero, Regular, Zero χωρίς καφεΐνη, Light 250ml',
                        id: '173',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Fanta - πορτοκάλι, μπλε, λεμόνι 250ml',
                        valueEng: 'Fanta',
                        valueGr: 'Fanta',
                        descriptionEng: 'orange, blue, lemon 250ml',
                        descriptionGr: 'πορτοκάλι, μπλε, λεμόνι 250ml',
                        id: '174',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Sprite - 250ml',
                        valueEng: 'Sprite',
                        valueGr: '',
                        descriptionEng: '250ml',
                        descriptionGr: '',
                        id: '175',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Schweppes - Indian Tonic Water, Soda Water, Pink grapefruit, Pink Tonic Style 250ml',
                        valueEng: 'Schweppes',
                        valueGr: '',
                        descriptionEng: 'Indian Tonic Water, Soda Water, Pink grapefruit, Pink Tonic Style 250ml',
                        descriptionGr: '',
                        id: '176',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Premium Αναψυκτικά',
                value: 'Pr.anapsyktika_02',
                className: 'title-menu-list notouch',
                children: [
                    {
                        label: 'Three Cents - Pink Grapefruit Soda, Sparkling Lemonade, Ginger Beer, Pineapple Soda, Mandarin & Bergamot Soda, Cherry Soda, Two Cents Plain, Pink Grapefruit Soda Zero 200ml',
                        valueEng: 'Three Cents',
                        valueGr: '',
                        descriptionEng: 'Pink Grapefruit Soda, Sparkling Lemonade, Ginger Beer, Pineapple Soda, Mandarin & Bergamot Soda, Cherry Soda, Two Cents Plain, Pink Grapefruit Soda Zero 200ml',
                        descriptionGr: '',
                        id: '177',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
        ],
    },
    {
        label: 'NCB’s',
        value: 'ncbs_01',
        className: 'title-menu-list',
        children: [

            {
                label: 'Fuze Tea - Μαύρο Τσάι Λεμόνι – Λουΐζα 500ml, Μαύρο Τσάι Ροδάκινο – Ιβίσκος 500ml, Μαύρο Τσάι Λεμόνι 0% ζάχαρη 500ml, Μαύρο Τσάι Ροδάκινο & Τριαντάφυλλο 0% ζάχαρη 500ml',
                valueEng: 'Fuze Tea',
                valueGr: 'Fuze Tea',
                descriptionEng: 'Βlack Tea Lemon – Lemongrass 500ml,  Black Tea Peach – Hibiscus 500ml,  Black Tea Lemon Zero Sugar 500ml,  Black Tea Peach Rose Zero Sugar 500ml, Green Tea Passionfruit Zero Sugar 500ml',
                descriptionGr: 'Μαύρο Τσάι Λεμόνι – Λουΐζα 500ml, Μαύρο Τσάι Ροδάκινο – Ιβίσκος 500ml, Μαύρο Τσάι Λεμόνι 0% ζάχαρη 500ml, Μαύρο Τσάι Ροδάκινο & Τριαντάφυλλο 0% ζάχαρη 500ml',
                id: '178',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Χυμός Amita',
                valueEng: 'Amita',
                valueGr: 'Χυμός Amita ',
                descriptionEng: '',
                descriptionGr: '',
                id: '179',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'AYΡΑ Ανθρακούχο Φυσικό Μεταλλικό Νερό 330ml',
                valueEng: 'AVRA Carbonated Mineral Water',
                valueGr: 'AYΡΑ Ανθρακούχο Φυσικό Μεταλλικό Νερό',
                descriptionEng: '330ml',
                descriptionGr: '330ml',
                id: '180',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'AΥΡΑ Aνθρακούχο Φυσικό Μεταλλικό Νερό 750ml',
                valueEng: 'AVRA Carbonated Mineral Water',
                valueGr: 'AΥΡΑ Aνθρακούχο Φυσικό Μεταλλικό Νερό',
                descriptionEng: '750ml',
                descriptionGr: '750ml',
                id: '181',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'AΥΡΑ Φυσικό Μεταλλικό Νερό 1L',
                valueEng: 'AVRA Natural Mineral Water ',
                valueGr: 'AΥΡΑ Φυσικό Μεταλλικό Νερό',
                descriptionEng: '1L',
                descriptionGr: '1L',
                id: '182',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'Lurisia Premium Ανθρακούχο Φυσικό Μεταλλικό Νερό 330ml (Bolle)',
                valueEng: 'Lurisia Premium Carbonated Natural Mineral Water',
                valueGr: 'Lurisia Premium Ανθρακούχο Φυσικό Μεταλλικό Νερό',
                descriptionEng: '330ml (Bolle)',
                descriptionGr: '330ml (Bolle)',
                id: '183',
                price: '',
                className: "desc-list",
                signature: ''
            },
        ],
    },
    {
        label: 'Spirits',
        value: 'spirits_0044',
        className: 'title-menu-list',
        children: [
            {
                label: 'Whisky',
                value: 'Whisky',
                className: 'title-menu-list',
                children: [
                    {
                        label: 'The Famous Grouse',
                        valueEng: 'The Famous Grouse',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '184',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'The Famous Grouse Smoky Black',
                        valueEng: 'The Famous Grouse Smoky Black',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '185',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Naked Malt',
                        valueEng: 'Naked Malt',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '186',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Jack Daniel’s',
                        valueEng: 'Jack Daniel’s',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '187',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Jack Daniel’s Tennessee Honey',
                        valueEng: 'Jack Daniel’s Tennessee Honey',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '188',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Jack Daniel’s Tennessee Apple',
                        valueEng: 'Jack Daniel’s Tennessee Apple',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '189',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Gentleman Jack',
                        valueEng: 'Gentleman Jack',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '190',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Jack Daniel’s Single Barrel',
                        valueEng: 'Jack Daniel’s Single Barrel',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '191',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Woodford Reserve',
                        valueEng: 'Woodford Reserve',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '192',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Woodford Reserve Rye',
                        valueEng: 'Woodford Reserve Rye',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '193',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Rum',
                value: 'Rum',
                className: 'title-menu-list',
                children: [
                    {
                        label: 'Brugal Anejo',
                        valueEng: 'Brugal Anejo',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '194',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Brugal 1888',
                        valueEng: 'Brugal 1888',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '195',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Vodka',
                value: 'Vodka',
                className: 'title-menu-list',
                children: [
                    {
                        label: 'Finlandia',
                        valueEng: 'Finlandia',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '196',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Tequila',
                value: 'Tequila',
                className: 'title-menu-list',
                children: [
                    {
                        label: 'el Jimador Blanco',
                        valueEng: 'el Jimador Blanco',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '197',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'el Jimador Reposado',
                        valueEng: 'el Jimador Reposado',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '198',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Herradura Reposado',
                        valueEng: 'Herradura Reposado',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '199',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Herradura Plata',
                        valueEng: 'Herradura Plata',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '200',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
            {
                label: 'Ελληνικά Αποστάγματα',
                value: 'ellhnikaapostagmata',
                className: 'title-menu-list',
                children: [
                    {
                        label: 'Ούζο Πλωμαρίου Ισιδώρου Αρβανίτoυ',
                        valueEng: 'Ούζο Πλωμαρίου Ισιδώρου Αρβανίτoυ',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '201',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Ούζο Ματαρέλλη',
                        valueEng: 'Ούζο Ματαρέλλη',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '202',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Ούζο Πλωμαρίου Άδολο',
                        valueEng: 'Ούζο Πλωμαρίου Άδολο',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '203',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Τσίπουρο Δεκαράκι Μονοποικιλιακό Μοσχάτο',
                        valueEng: 'Τσίπουρο Δεκαράκι Μονοποικιλιακό Μοσχάτο',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '204',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Τσίπουρο Δεκαράκι Μονοποικιλιακό Μαλαγουζιά',
                        valueEng: 'Τσίπουρο Δεκαράκι Μονοποικιλιακό Μαλαγουζιά',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '205',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                    {
                        label: 'Τσίπουρο Δεκάρακι Μονοποικιλιακό Ροδίτης',
                        valueEng: 'Τσίπουρο Δεκάρακι Μονοποικιλιακό Ροδίτης',
                        valueGr: '',
                        descriptionEng: '',
                        descriptionGr: '',
                        id: '206',
                        price: '',
                        className: "desc-list",
                        signature: ''
                    },
                ],
            },
        ],
    },
    {
        label: 'Beers',
        value: 'beers_09',
        className: 'title-menu-list',
        children: [
            {
                label: 'CORONA 330ml',
                valueEng: 'CORONA 330ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '207',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'STELLA ARTOIS 330ml',
                valueEng: 'STELLA ARTOIS 330ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '208',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'STELLA ARTOIS 0.0% alcohol 330ml',
                valueEng: 'STELLA ARTOIS 0.0% alcohol 330ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '209',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'BUD 330ml',
                valueEng: 'BUD 330ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '210',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'BECK’S 275ml',
                valueEng: 'BECK’S 275ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '211',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'FRANZISKANER WEISSBIER 500ml',
                valueEng: 'FRANZISKANER WEISSBIER 500ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '212',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'LEFFE BLONDE 330ml',
                valueEng: 'LEFFE BLONDE 330ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '213',
                price: '',
                className: "desc-list",
                signature: ''
            },
            {
                label: 'LEFFE BRUNE 330ml',
                valueEng: 'LEFFE BRUNE 330ml',
                valueGr: '',
                descriptionEng: '',
                descriptionGr: '',
                id: '214',
                price: '',
                className: "desc-list",
                signature: ''
            },

        ],
    },



]