import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import Page from './../../../../src/assets/page.png'
import Page4 from './../../../../src/assets/page4.png'
import Page8 from './../../../../src/assets/page8.png'
import Page16 from './../../../../src/assets/page16.png'

const TemplateVariations = ({ variations, formData, setFormData, current }) => {

    const [selectElement, setSelectElement] = useState(null)

    const selectHandle = (item, idx) => {
        setSelectElement(idx)
        setFormData({ ...formData, typePage: item })
    }

    useEffect(() => {
        setSelectElement(current)
    }, [current])


    return (
        <>
            <div class="flex">
                {variations.map((item, idx) => (
                    <div key={idx} class='my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-1 lg:w-1/4'>
                        <article class='overflow-hidden rounded-lg shadow-lg'>
                            <div onClick={() => selectHandle(item, idx)} className={`${selectElement === idx ? 'bg-red-500' : ''}`}>
                                {item === 'Μονόφυλλο' ?
                                    <img class={`block h-auto w-full ${selectElement === idx ? 'bg-red-500' : ''}`}
                                        style={{ background: '#e0e0e0', padding: '30px' }}
                                        src={Page} alt="card"
                                    /> : ''}
                                {item === '4 Σέλιδο' ?
                                    <img class={`block h-auto w-full ${selectElement === idx ? 'bg-red-500' : ''}`}
                                        style={{ background: '#e0e0e0', padding: '30px' }}
                                        src={Page4} alt="card"
                                    /> : ''}
                                {item === '8 Σέλιδο' ?
                                    <img class={`block h-auto w-full ${selectElement === idx ? 'bg-red-500' : ''}`}
                                        style={{ background: '#e0e0e0', padding: '30px' }}
                                        src={Page8} alt="card"
                                    /> : ''}
                                {item === '16 Σέλιδο' ?
                                    <img class={`block h-auto w-full ${selectElement === idx ? 'bg-red-500' : ''}`}
                                        style={{ background: '#e0e0e0', padding: '30px' }}
                                        src={Page16} alt="card"
                                    /> : ''}
                            </div>
                            <header class="leading-tight p-2 md:p-4">
                                <h1 class="text-md  font-normal text-black text-center">
                                    <Link to={`/templates/`} class="no-underline">
                                        {item}
                                    </Link>
                                </h1>
                            </header>
                        </article>
                    </div>
                ))}
            </div>
        </>
    )
}

export default TemplateVariations