import React, { useState } from 'react'
import { IconButton } from "@material-tailwind/react";
import { useDispatch } from 'react-redux';
import { incremented, decremented } from './../../../lib/features/layout/zoomSlice';

import { AiOutlineZoomIn } from "react-icons/ai";
import { AiOutlineZoomOut } from "react-icons/ai";
import { LiaUndoSolid } from "react-icons/lia";
import { LiaRedoSolid } from "react-icons/lia";

const Helper = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)

    const handleIncrement = () => {
        dispatch(incremented());
    };

    const handleDecrement = () => {
        dispatch(decremented());
    };

    return (
        <div className="relative w-full h-80">
            <div className="absolute bottom-0 right-0">
                <div className="group">
                    <button onClick={() => setShow(!show)}
                        className="relative h-12 max-h-[48px] w-12 max-w-[48px] mt-10 select-none overflow-hidden rounded-lg bg-gray-900 text-center align-middle font-sans text-sm font-medium uppercase text-white shadow-md shadow-gray-900/10 transition-all"
                        type="button">
                        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" aria-hidden="true" className="w-5 h-5 transition-transform">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                            </svg>
                        </span>
                    </button>
                    <div
                        className={`${!show ? 'hidden' : 'display'} absolute top-[-173px] left-[-4px] w-max  flex-col items-center gap-1 p-0.5`}>

                        <div data-projection-id="2">
                            <IconButton variant="outlined" onClick={handleIncrement} className="m-0.5 flex min-h-[48px] min-w-[48px] flex-col items-center justify-center gap-1 rounded-lg">
                                <AiOutlineZoomIn className="h-4 w-4" />
                            </IconButton>
                        </div>

                        <div data-projection-id="1">
                            <IconButton variant="outlined" onClick={handleDecrement} className="m-0.5 flex min-h-[48px] min-w-[48px] flex-col items-center justify-center gap-1 rounded-lg">
                                <AiOutlineZoomOut className="h-4 w-4" />
                            </IconButton>
                        </div>

                        <div data-projection-id="3" >
                            <IconButton variant="outlined" className="m-0.5 flex min-h-[48px] min-w-[48px] flex-col items-center justify-center gap-1 rounded-lg">
                                <LiaUndoSolid className="h-4 w-4" />
                            </IconButton>
                        </div>

                        <div data-projection-id="4">
                            <IconButton variant="outlined" className="m-0.5 flex min-h-[48px] min-w-[48px] flex-col items-center justify-center gap-1 rounded-lg">
                                <LiaRedoSolid className="h-4 w-4" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Helper)