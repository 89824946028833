import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import CocktailsPages from './cocktails';
import ComboPages from './combo';
import CatalogPages from './catalog';

const Pages = () => {

    const template = useSelector((state) => state.template.template)

    const renderPages = () => {
        if (template.type === 'cocktail') {
            return <CocktailsPages />
        }
        if (template.type === 'combo') {
            return <ComboPages />
        }
        if (template.type === 'catalog') {
            return <CatalogPages />
        }
    }

    return (renderPages())
}

export default Pages