import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './lib/store'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ToastContainer />
    <Provider store={store}>

      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>
          <App />
        </Orientation>
        <Orientation orientation='portrait' alwaysRender={false}>
          <div className='w-full h-screen flex flex-col justify-center mx-auto text-center'>
            <h2 class='text-2xl'>Please rotate your device</h2>
          </div>
        </Orientation>
        <App />
      </DeviceOrientation>
    </Provider>
  </BrowserRouter>
);
