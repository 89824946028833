import React from 'react'

const Variation2 = ({ state, styles }) => {
    
    {/* BEIGE 02, BEIGE 07, BLACK 02, WHITE 02 */ }
    return (
        <div className='menu-item-wrapper realtive'>
            <div className='block-1 relative inline-flex gap-x-2 items-center'>
                <div className='menu-item-title'
                    style={{
                        fontSize: `${styles.titleFontSize}`,
                        fontWeight: 'bolder',
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.titleColor}`,
                        textTransform: `${styles.textTransform}`,
                        display: 'block'
                    }}> {state.valueEng} </div>
                <div className='menu-item-price'
                    style={{
                        fontSize: `${styles.priceFontSize}`,
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.priceColor}`,
                        textTransform: `${styles.textTransform}`
                    }}> <div className='inline-flex pr-2'>-</div> {state.price ? state.price + ' €' : ' €'} </div>
            </div>
            <div className='menu-item-description'
                style={{
                    fontSize: `${styles.subtitleFontSize}`,
                    fontFamily: `${styles.titleFontFamily}`,
                    color: `${styles.subtitleColor}`,
                    textTransform: `${styles.textTransform}`,
                }}> {state.descriptionEng} </div>
        </div>
    )
}

export default Variation2