import { configureStore } from '@reduxjs/toolkit'
import { zoomReducer } from './features/layout/zoomSlice.js'
import { menuReducer } from './features/menu/menuSlice.js'
import { pageReducer } from './features/layout/page.js'
import { counterReducer } from './features/helper/counterSlice.js'
import { templateReducer } from './features/template/templateSlice.js'
import { userReducer } from './features/user/userSlice.js'

const store = configureStore({
    reducer: {
        zoom: zoomReducer,
        menu: menuReducer,
        page: pageReducer,
        counter: counterReducer,
        template: templateReducer,
        auth: userReducer
    },
})

export default store