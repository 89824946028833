import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { removeTemplateMenuCombo } from '../../../../lib/features/template/templateSlice';

import {
    Dialog,
    DialogBody,
} from "@material-tailwind/react";

import { FaRegTrashAlt } from "react-icons/fa";
import OfferGrid from '../../offer/offerGrid';

import { FaHotdog } from "react-icons/fa6";
import { PiBeerBottleFill } from "react-icons/pi";

const ProductsOffer = () => {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState('')

    const template = useSelector((state) => state.template.template)
    const page = useSelector((state) => state.page.current)

    const [productDrink, setProductDrink] = useState('')
    const [productFood, setProductFood] = useState('')

    const handleOpen = (cat) => {
        setCategory(cat)
        setOpen(!open);
    }

    useEffect(() => {
        template.pages[page].map((obj, idx) => {
            obj.items.map((product) => {
                if (product.category === 'drink') { setProductDrink(product) }
                if (product.category === 'food') { setProductFood(product) }
            })
        })
    }, [template])

    const handleRemove = (id, category) => {
        dispatch(removeTemplateMenuCombo({ page: page, id: id }))
        if (category === 'drink') { setProductDrink('') }
        if (category === 'food') { setProductFood('') }
    }

    return (
        <div>
            <div className="text-2xl font-semibold py-2">Προσθήκη προϊόν</div>
            <div className="mt-5">
                <div class="flex flex-wrap -mx-1 lg:-mx-4">
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <article className="overflow-hidden rounded-lg">
                            {productDrink ? (
                                <div className="block"
                                    style={{ height: '150px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor: '#e0e0e0', backgroundImage: `url(${productDrink.img})` }}>
                                    <div
                                        onClick={() => handleRemove(productDrink.id, productDrink.category)}
                                        className="bg-red-500 py-3 px-3 rounded-md cursor-default text-white inline-block m-2">
                                        <FaRegTrashAlt />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    onClick={() => handleOpen('drink')}
                                    className="block border-2 border-dashed border-gray-400 bg-white p-6 text-center hover:bg-gray-100"
                                    style={{ height: '150px', display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '0 auto', alignItems: 'center' }} >
                                    <PiBeerBottleFill className='h-12 w-12' />
                                </div>
                            )}
                        </article>
                    </div>
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                        <article className="overflow-hidden rounded-lg">
                            {productFood ? (
                                <div className="block"
                                    style={{ height: '150px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor: '#e0e0e0', backgroundImage: `url(${productFood.img})` }} >
                                    <div
                                        onClick={() => handleRemove(productFood.id, productFood.category)}
                                        className="bg-red-500 py-3 px-3 rounded-md cursor-default text-white inline-block m-2">
                                        <FaRegTrashAlt />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    onClick={() => handleOpen('food')}
                                    className="block border-2 border-dashed border-gray-400 bg-white p-6 text-center hover:bg-gray-100"
                                    style={{ height: '150px', display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '0 auto', alignItems: 'center' }} >
                                    <FaHotdog className='h-12 w-12' />
                                </div>
                            )}
                        </article>
                    </div>
                </div>
            </div>

            <Dialog open={open} handler={handleOpen} size="md" className='overflow-scroll max-h-[90vh] min-h-[90vh]'>
                <DialogBody>
                    <OfferGrid setOpen={setOpen} open={open} category={category} />
                </DialogBody>
            </Dialog>
        </div>
    )
}

export default React.memo(ProductsOffer)