import React from 'react'
import { useSelector } from 'react-redux'
import Variation1 from './variations/variation1'
import Variation2 from './variations/variation2'
import Variation3 from './variations/variation3'
import Variation4 from './variations/variation4'
import Variation5 from './variations/variation5'
import Variation6 from './variations/variation6'
import Variation7 from './variations/variation7'

const CoctailMenuItem = ({ state, styles, template }) => {

    const align = useSelector((state) => state.page.align)
    const signature = useSelector((state) => state.menu.signature)

    return (
        // <div className="item-list" style={{
        //     margin: `${styles.itemsMargin}`,
        //     position: 'relative'
        // }}>
        //     <div style={{
        //         display: 'flex',
        //         justifyContent: align,
        //         position: 'relative',
        //         marginBottom: '10px'
        //     }}>

        //         {signature === true ? <img src={state.signature}
        //             style={{
        //                 width: '120px',
        //                 left: '320px',
        //                 top: '-30px',
        //                 position: 'absolute'
        //             }} />
        //             : ''}

        //         {/* /*############################/*
        //         /*           Title               /*
        //         /*############################/* */}
        //         <div style={{
        //             fontSize: `${styles.titleFontSize}`,
        //             fontWeight: 'bolder',
        //             fontFamily: `${styles.titleFontFamily}`,
        //             color: `${styles.titleColor}`,
        //             display: 'block'
        //         }}>
        //             {state.valueEng}
        //         </div>

        //         {/* /*############################/*
        //         /*           Price              /*
        //         /*############################/* */}
        //         <div style={{
        //             position: 'absolute',
        //             right: `${styles.priceRight}`,
        //             top: `${styles.priceTop}`,
        //             fontSize: `${styles.priceFontSize}`,
        //             fontFamily: `${styles.titleFontFamily}`,
        //             color: `${styles.priceColor}`
        //         }}>
        //             {state.price ? state.price + ' €' : '__ €'}
        //         </div>
        //     </div>

        //     {/* /*############################/*
        //     /*          Description         /*
        //     /*############################/* */}
        //     <div style={{
        //         fontSize: `${styles.subtitleFontSize}`,
        //         fontFamily: `${styles.titleFontFamily}`,
        //         color: `${styles.subtitleColor}`
        //     }}>
        //         {state.descriptionEng}
        //     </div>

        //     {/* /*############################/*
        //     /*           Decor              /*
        //     /*############################/* */}

        //     <div style={{
        //         position: 'absolute',
        //         minHeight: `${styles.decorMinHeight}`,
        //         backgroundImage: `url(${styles.decor})`,
        //         backgroundSize: '100% 100%',
        //         backgroundRepeat: 'no-repeat',
        //         minWidth: `${styles.decorMinWidth}`,
        //         top: `${styles.decorTop}`,
        //         bottom: `${styles.decorBottom}`
        //     }} />
        // </div>

        <div className="item-list" style={{
            margin: `${styles.itemsMargin}`,
            padding: `${styles.itemsPadding}`,
            position: 'relative'
        }}>

            {template.list === 'variation1' ? <Variation1 state={state} styles={styles} /> : ''}
            {template.list === 'variation2' ? <Variation2 state={state} styles={styles} /> : ''}
            {template.list === 'variation3' ? <Variation3 state={state} styles={styles} /> : ''}
            {template.list === 'variation4' ? <Variation4 state={state} styles={styles} /> : ''}
            {template.list === 'variation5' ? <Variation5 state={state} styles={styles} /> : ''}
            {template.list === 'variation6' ? <Variation6 state={state} styles={styles} /> : ''}
            {template.list === 'variation7' ? <Variation7 state={state} styles={styles} /> : ''}

        </div >

    )
}

export default CoctailMenuItem