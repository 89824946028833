import React, { useEffect, useState } from 'react'

import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";

import TemplateVariations from './TemplateVariations';

import { IoInformationCircleOutline } from "react-icons/io5";

const TemplateSizes = ({ formData, setFormData }) => {

    const [current, setCurrent] = useState('')

    useEffect(() => {
        setCurrent(null)
    })


    const data = [
        {
            label: "A3",
            value: "A3",
            desc: <TemplateVariations
                variations={['Μονόφυλλο']}
                formData={{ sizePage: 'A3' }}
                setFormData={setFormData}
                current={current} />,
        },
        {
            label: "A4",
            value: "A4",
            desc: <TemplateVariations
                variations={['Μονόφυλλο', '4 Σέλιδο', '8 Σέλιδο', '16 Σέλιδο']}
                formData={{ sizePage: 'A4' }}
                setFormData={setFormData}
                current={current} />,
        },
        {
            label: "A5",
            value: "A5",
            desc: <TemplateVariations
                variations={['Μονόφυλλο', '4 Σέλιδο', '8 Σέλιδο', '16 Σέλιδο']}
                formData={{ sizePage: 'A5' }}
                setFormData={setFormData}
                current={current} />,
        },
        {
            label: "15x33",
            value: "15x33",
            desc: <TemplateVariations
                variations={['Μονόφυλλο', '4 Σέλιδο', '8 Σέλιδο', '16 Σέλιδο']}
                formData={{ sizePage: '15x33' }}
                setFormData={setFormData}
                current={current} />,
        },
    ];

    return (
        <div class="container my-4 mx-auto">
            <div className='flex gap-x-2 items-center bg-red-500 bg-opacity-60 text-white rounded-lg px-4 mb-5'>
                <IoInformationCircleOutline className='h-7 w-7' />
                <div className='my-4 text-xl font-semibold'>Επιλέξτε διαστάσεις</div>
            </div>
            <div class="-mx-1 lg:-mx-4 my-4">
                <Tabs value="A3">
                    <TabsHeader className="mx-4 mb-2">
                        {data.map(({ label, value }) => (
                            <Tab key={value}
                                value={value}
                                style={{ fontSize: '22px', fontWeight: 'bold', padding: '10px' }}
                                onClick={() => setCurrent('')}>
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody>
                        {data.map(({ value, desc }) => (
                            <TabPanel key={value} value={value}>
                                {desc}
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </div>
    )
}

export default TemplateSizes