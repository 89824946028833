import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axios';



// Register
export const fetchRegister = createAsyncThunk(
    'auth/fetchRegister',
    async (params) => {
        const { data } = await axios.post('/auth/register', params)
        return data;
    },
);

// Login
export const fetchLogin = createAsyncThunk(
    'auth/fetchLogin',
    async (params) => {
        const { data } = await axios.post('/auth/login', params)
        return data;
    },
);

// Get me
export const fetchMe = createAsyncThunk(
    'auth/fetchAuthMe',
    async () => {
        const { data } = await axios.get('/auth/me')
        return data;
    },
);

const initialState = {
    data: null,
    status: 'loading',
};

const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        logout: (state) => {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Register
            .addCase(fetchRegister.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(fetchRegister.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(fetchRegister.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })

            // Login
            .addCase(fetchLogin.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(fetchLogin.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(fetchLogin.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })

            // Get me
            .addCase(fetchMe.pending, (state) => {
                state.status = 'loading';
                state.data = null;
            })
            .addCase(fetchMe.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            })
            .addCase(fetchMe.rejected, (state, action) => {
                state.status = 'error';
                state.data = null;
            })
    }
});

export const selectIsAuth = state => state.auth.data;

export const { logout } = userSlice.actions;
export const userReducer = userSlice.reducer;
