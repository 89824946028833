import React, { useState } from 'react'
import { Select, Option, Button } from "@material-tailwind/react";
import { useDropzone } from 'react-dropzone'

import { BsFileText } from "react-icons/bs";

import { logos } from './../../../../data/logos'

import { useDispatch, useSelector } from 'react-redux';
import { loadBrandLogo, loadFile, removeFile } from './../../../../lib/features/layout/page';

import axios from '../../../../axios'

const BrandLogo = () => {

    const dispatch = useDispatch();
    const brandLogo = useSelector((state) => state.page.brandLogo)
    const file = useSelector((state) => state.page.file)

    const [dataUrl, SetDataUrl] = useState('')

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles) {
            try {
                const formData = new FormData();
                const file = acceptedFiles[0]
                formData.append('image', file);
                const { data } = await axios.post('/uploadfile', formData);
                const UploadFile = (`https://printit-app-73bf3b59ee9c.herokuapp.com/uploads/${data.url}`);
                dispatch(loadFile(UploadFile))
            } catch (err) {
                console.warn(err);
                alert('Error upload file')
            }
        }
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const handleRemoveFile = () => {
        dispatch(removeFile())
    }

    const handleChange = (val) => {
        dispatch(loadBrandLogo(val))
    }

    const url = 'https://printit-app-73bf3b59ee9c.herokuapp.com/uploads/'

    return (
        <>
            <div className="text-2xl font-semibold py-2">Προσθήκη brand logo</div>
            <div className="mt-5">
                <div>
                    <div className='flex w-100 h-32 flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-white p-6 text-center'>
                        {brandLogo ? <img src={brandLogo} alt="logoBrand" className='h-20' /> : ''}
                    </div>
                    <div className="w-full mt-5">
                        <Select
                            label="Επιλέξτε Brand"
                            value={brandLogo}
                            onChange={(val) => handleChange(val)}
                        >
                            {logos.map((select, idx) => (
                                <Option key={idx} value={select.logo}>{select.value}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div {...getRootProps()} className="mt-20">
                    <h6
                        className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                        Προσθήκη αρχείου
                    </h6>
                    {file ?
                        (
                            <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-white p-6 text-center hover:bg-gray-100">
                                <div className='text-center'>
                                    <BsFileText className="text-4xl" />
                                </div>
                                <div className='mt-3'>
                                    <div className='text-xs'>{file.replace(url, '')}</div>
                                    <div>Επιτυχής μεταφόρτωση αρχείου!</div>
                                </div>
                            </label>
                        )
                        : (
                            <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-white p-6 text-center hover:bg-gray-100">
                                <BsFileText className="text-4xl" />
                                <h2 className="mt-4 text-sm font-medium text-gray-700 tracking-wide">Click to upload  or drag and drop</h2>
                            </label>
                        )}
                    <input id="dropzone-file" type="file" className="hidden"  {...getInputProps()} />
                </div>
            </div>

            {file ?
                <Button onClick={handleRemoveFile} className="mt-5 capitalize" color="red" >Διαγραφή</Button>
                : ''}
        </>
    )
}

export default React.memo(BrandLogo)