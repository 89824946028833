import React, { useEffect } from 'react'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Card
} from "@material-tailwind/react";

import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates } from './../../lib/features/template/templateSlice';
import { fetchMe } from './../../lib/features/user/userSlice';
import { currStepSet, removePDFFile, removeFile } from './../../lib/features/layout/page';
import { reset } from './../../lib/features/helper/counterSlice';

import TopbarHome from '../../components/Home/topbar';

import Fsr from './fsr';
import Cafebar from './cafebar';
import Qsr from './qsr';

const Home = () => {

    const data = [
        {
            label: "FSR",
            value: "fsr",
            desc: <Fsr />,
        },
        {
            label: "CafeBar",
            value: "cafebar",
            desc: <Cafebar />,
        },
        {
            label: "QSR",
            value: "qsr",
            desc: <Qsr />,
        },
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMe());
    }, []);

    useEffect(() => {
        dispatch(fetchTemplates())
    }, [])

    useEffect(() => {
        dispatch(currStepSet(1));
        dispatch(removePDFFile());
        dispatch(removeFile())
        dispatch(reset())
    }, [])

    return (
        <Card className="h-screen w-full bg-gray-100">
            <TopbarHome />
            <Tabs value="fsr">
                <TabsHeader style={{ margin: '30px', background: '#e0e0e0', borderRadius: '10px' }} className=''>
                    {data.map(({ label, value }) => (
                        <Tab key={value} value={value} style={{ padding: '20px', fontSize: '25px', fontWeight: '600', textTransform: 'uppercase' }}>
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody
                    className='bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]'
                    animate={{
                        initial: { y: 250 },
                        mount: { y: 0 },
                        unmount: { y: 250 },
                    }}>
                    {data.map(({ value, desc }) => (
                        <TabPanel key={value} value={value}>
                            {desc}
                        </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </Card>
    )
}

export default React.memo(Home)