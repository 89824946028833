import React from "react";
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { forward, back, oneColumn, twoColumn, alignLeft, alignCenter, currStepSet, pageNav } from './../../../lib/features/layout/page';

import { Button, IconButton, ButtonGroup, Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import logo from './../../../assets/logo.png'

import { MdOutlineArrowBackIosNew } from "react-icons/md";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import { CiTextAlignCenter } from "react-icons/ci";
import { CiTextAlignLeft } from "react-icons/ci";

import { BsLayoutSidebarReverse } from "react-icons/bs";
import { BsLayoutSplit } from "react-icons/bs";
import { BsLayoutThreeColumns } from "react-icons/bs";

const Topbar = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const page = useSelector((state) => state.page)

    const handleForward = () => {
        dispatch(forward());
    };

    const handleBack = () => {
        dispatch(back());
    };

    const setOneColumn = () => {
        dispatch(oneColumn());
    };

    const setTwoColumn = () => {
        dispatch(twoColumn());
    };

    const setAlignLeft = () => {
        dispatch(alignLeft());
    };

    const setAlignCenter = () => {
        dispatch(alignCenter());
    };

    const currentStep = () => {
        dispatch(currStepSet(0));
        dispatch(pageNav(false))
        navigate("/home");
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(!open);

    return (
        <>
            <header className="flex h-18 items-center justify-between gap-x-6 bg-white px-6 py-4 border border-b">


                <div onClick={currentStep}>
                    <div className="flex items-center flex-row gap-x-5">
                        <IconButton>
                            <MdOutlineArrowBackIosNew className="h-4 w-4" />
                        </IconButton>
                        <img src={logo} alt="img" className="object-cover h-12" />
                    </div>
                </div>


                {/* <Button className="flex items-center gap-2 font-light" variant="outlined" onClick={handleOpen} disabled>
                    <BsLayoutWtf className="h-4 w-4" />
                </Button> */}

                <div className="flex items-center gap-x-3">
                    <ButtonGroup variant="outlined">
                        <Button onClick={setAlignLeft}><CiTextAlignLeft className="h-4 w-4" /></Button>
                        <Button onClick={setAlignCenter}><CiTextAlignCenter className="h-4 w-4" /></Button>
                    </ButtonGroup>
                </div>

                <div className="flex items-center gap-x-3">
                    <ButtonGroup variant="outlined">
                        <Button onClick={setOneColumn}><BsLayoutSidebarReverse className="h-4 w-4" /></Button>
                        <Button disabled onClick={setTwoColumn}><BsLayoutSplit className="h-4 w-4" /></Button>
                        <Button disabled onClick={setOneColumn}><BsLayoutThreeColumns className="h-4 w-4" /></Button>
                    </ButtonGroup>
                </div>

                <div className="flex flex-row gap-x-2">
                    <ButtonGroup variant="outlined">
                        <Button disabled={(page.current === 0) || (page.pageNav === true)} onClick={handleBack}><IoIosArrowBack className="h-4 w-4" /></Button>
                        <Button disabled={page.pageNav === true} className="font-light">{page.current + 1} / {page.total + 1}</Button>
                        <Button disabled={(page.current === page.total) ||  (page.pageNav === true)} onClick={handleForward}><IoIosArrowForward className="h-4 w-4" /></Button>
                    </ButtonGroup>
                </div>
            </header>

            {/* <Dialog open={open} handler={handleOpen} size='lg'>
                <DialogHeader>Templates</DialogHeader>
                <DialogBody>

                </DialogBody>
            </Dialog> */}
        </>
    )
}

export default React.memo(Topbar)