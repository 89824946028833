import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import { IoInformationCircleOutline } from "react-icons/io5";

const TemplatesPreview = ({ category }) => {

    const templates = useSelector((state) => state.template.templates)

    return (
        <div class="container my-4 mx-auto">
            <div className='flex gap-x-2 items-center bg-red-500 bg-opacity-60 text-white rounded-lg px-4 mb-5'>
                <IoInformationCircleOutline className='h-7 w-7' />
                <div className='my-4 text-xl font-semibold'>Επιλέξτε  template</div>
            </div>
            <div class="flex flex-wrap -mx-1 lg:-mx-4">
                {templates.map((obj, idx) => (
                    obj.type === category ? (
                        <div key={idx} class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                            <article class="overflow-hidden rounded-lg shadow-lg">
                                <Link to={`/templates/${obj._id}`}>
                                    <img class="block h-auto w-full" style={{ background: '#e0e0e0', padding: '30px' }} src={obj.preview} alt="card" />
                                </Link>
                                <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                                    <h1 class="text-md  font-normal text-black">
                                        <Link to={`/templates/${obj._id}`} class="no-underline">
                                            {obj.title}
                                        </Link>
                                    </h1>
                                    <p class="text-grey-darker text-sm">
                                        Σελίδες: {obj.pages.length}
                                    </p>
                                </header>
                            </article>
                        </div>
                    ) : ''
                ))}
            </div>
        </div>
    )
}

export default React.memo(TemplatesPreview)