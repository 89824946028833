import React, { useState } from 'react'
import { Link } from "react-router-dom";
import {
    Typography,
    Card,
    CardBody,
    Dialog,
    DialogBody
} from "@material-tailwind/react";

import { LuLayers } from "react-icons/lu";
import { PiVideoBold } from "react-icons/pi";
import { MdOutlineQrCode2 } from "react-icons/md";

import Templates from '../../../components/Home/templates';

const Qsr = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const [category, setCategory] = useState('')

    const selectHandle = (category) => {
        setCategory(category)
        handleOpen()
    }

    return (
        <>
            <div className="grid grid-cols-3 grid-rows-1 gap-4 p-4">
                <Card className="mt-6 w-100" onClick={() => selectHandle('combo')}>
                    <div color="blue-gray" className="relative h-56 flex justify-center flex-col m-auto">
                        <LuLayers className='text-9xl' />
                    </div>
                    <CardBody className='text-center'>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                            COMBO
                        </Typography>
                    </CardBody>
                </Card>

                <Link to="/home">
                    <Card className="mt-6 w-100">
                        <div color="blue-gray" className="relative h-56 flex justify-center flex-col m-auto">
                            <PiVideoBold className='text-9xl' />
                        </div>
                        <CardBody className='text-center'>
                            <Typography variant="h5" color="blue-gray" className="mb-2">
                                VIDEO
                            </Typography>
                        </CardBody>
                    </Card>
                </Link>

                <Card className="mt-6 w-100" onClick={() => selectHandle('qsr')}>
                    <div color="blue-gray" className="relative h-56 flex justify-center flex-col m-auto">
                        <MdOutlineQrCode2 className='text-9xl' />
                    </div>
                    <CardBody className='text-center'>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                            FSA's
                        </Typography>
                    </CardBody>
                </Card>

            </div>

            <Dialog open={open} handler={handleOpen} size="xl" className='overflow-scroll max-h-[90vh]'>
                <DialogBody>
                    <Templates category={category} />
                </DialogBody>
            </Dialog>
        </>
    )
}

export default Qsr