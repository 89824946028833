import React from "react";
import { useSelector } from 'react-redux';

import StepperCocktail from "./stepperCocktail";
import StepperCombo from "./stepperCombo";

import Loader from "../../Layout/loader/index.jsx";

const StepperComponent = () => {

    const template = useSelector((state) => state.template.template)
    const loading = useSelector((state) => state.template.loading)

    const StepperSwitchType = () => {
        if (template.type === 'cocktail') {
            return <StepperCocktail type={'coctail'} />
        }
        if (template.type === 'combo') {
            return <StepperCombo type={'combo'} />
        }
        if (template.type === 'catalog') {
            return <StepperCocktail type={'catalog'} />
        }
    }

    return (
        loading === 'loaded' ? StepperSwitchType() : <Loader />
    )
}

export default StepperComponent