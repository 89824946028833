import React from 'react'

const Variation6 = ({ state, styles }) => {

    {/* BLACK 02  */ }
    return (
        <div div className='menu-item-wrapper realtive'>
            <div className='block-1 relative'>
                <div className='menu-item-title'
                    style={{
                        fontSize: `${styles.titleFontSize}`,
                        fontWeight: '600',
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.titleColor}`,
                        display: 'block',
                        backgroundImage: `url(${styles.decor})`,
                        backgroundSize: '70% 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}> {state.valueEng} </div>

                <div className='menu-item-price absolute -right-8 top-2'
                    style={{
                        fontSize: `${styles.priceFontSize}`,
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.priceColor}`
                    }}
                > {state.price ? state.price + ' €' : '__ €'} </div>
            </div>
            <div className='menu-item-description'
                style={{
                    fontSize: `${styles.subtitleFontSize}`,
                    fontFamily: `${styles.titleFontFamily}`,
                    color: `${styles.subtitleColor}`,
                    padding: '5px 8px'
                }}> {state.descriptionEng} </div>
        </div >
    )
}

export default Variation6