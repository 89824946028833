import { Spinner } from "@material-tailwind/react";

const Loader = () => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center z-50 absolute top-0 right-0 bg-white">
        <Spinner className="h-12 w-12"/>
    </div>
  )
}

export default Loader