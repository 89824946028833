import React from 'react'

import { products } from '../../../../data/products'
import { useDispatch, useSelector } from 'react-redux'
import { addTemplateMenuCombo } from '../../../../lib/features/template/templateSlice'

import { IoInformationCircleOutline } from "react-icons/io5";

const OfferGrid = ({ open, setOpen, category }) => {

    const dispatch = useDispatch()
    const page = useSelector((state) => state.page.current)

    const handleSelect = (id, category, img) => {
        const data = {
            id: id,
            category: category,
            img: img
        }
        dispatch(addTemplateMenuCombo({ data: data, page: page, id: id }))
        setOpen(!open)
    }

    return (
        <div>
            <div className="container my-4 mx-auto px-4 md:px-4">
                <div className='flex gap-x-2 items-center bg-red-500 bg-opacity-60 text-white rounded-lg px-4 mb-5'>
                    <IoInformationCircleOutline className='h-7 w-7' />
                    <div className='my-4 text-xl  font-semibold'>Επιλέξτε προϊόν</div>
                </div>
                <div className="flex flex-wrap -mx-1 lg:-mx-4">
                    {products.map((product, idx) => (
                        product.category === category ? (
                            <div key={idx} className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4">
                                <article className="overflow-hidden rounded-lg"
                                    onClick={() => handleSelect(product.id, product.category, product.img)}
                                >
                                    <div className="block" style={{ height: '150px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor: '#e0e0e0', backgroundImage: `url(${product.img})` }} />
                                </article>
                            </div>
                        ) : ''
                    ))}
                </div>
            </div>
        </div>
    )
}

export default React.memo(OfferGrid)