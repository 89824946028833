import React from 'react'

const Variation4 = ({ state, styles }) => {

    {/* BEIGE 04, BEIGE 05, BLACK 01, BLACK 03, WHITE 03, WHITE 04 */ }
    return (
        <div className='menu-item-wrapper realtive block'>
            <div className='block-1 relative flex justify-between'>
                <div className='menu-item-title'
                    style={{
                        fontSize: `${styles.titleFontSize}`,
                        fontWeight: 'bolder',
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.titleColor}`,
                        display: 'block'
                    }}> {state.valueEng} </div>
                <div className='menu-item-price'
                    style={{
                        fontSize: `${styles.priceFontSize}`,
                        fontFamily: `${styles.titleFontFamily}`,
                        color: `${styles.priceColor}`
                    }}> {state.price ? state.price + ' €' : ' €'} </div>
            </div>
            <div className='menu-item-description text-left float-left'
                style={{
                    fontSize: `${styles.subtitleFontSize}`,
                    fontFamily: `${styles.subtitleFontFamily}`,
                    color: `${styles.subtitleColor}`,
                    fontWeight: `${styles.subtitleFontWeidth}`
                }}> {state.descriptionEng} </div>
        </div>
    )
}

export default Variation4