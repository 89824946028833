import { createSlice } from '@reduxjs/toolkit'

const zoomSlice = createSlice({
    name: 'Zoom',
    initialState: {
        value: 60
    },
    reducers: {
        incremented: state => {
            state.value += 10
        },
        decremented: state => {
            state.value -= 10
        },
        reset: state => {
            state.value = 60
        },
        setZoom: (state, action)  => {
            state.value = action.payload
        }
    }
})

export const { incremented, decremented, reset, setZoom } = zoomSlice.actions
export const zoomReducer = zoomSlice.reducer
