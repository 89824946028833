import React from 'react'
import { Button } from "@material-tailwind/react";

import { useDispatch } from 'react-redux'
import { removeMenuItem } from './../../../../../lib/features/menu/menuSlice'

import { FaRegTrashAlt } from "react-icons/fa";

const CustomListItem = ({ item }) => {

    const dispatch = useDispatch()

    const removeMenuItemHandler = (id) => {
        dispatch(removeMenuItem(id))
    }

    return (
        <div className="flex items-center w-full justify-between gap-x-4 py-2">
            <div className='w-full break-words'>
                <div className="text-md font-semibold mb-1">{item.valueEng}</div>
                <div className="text-sm">{item.descriptionEng}</div>
            </div>
            <Button onClick={() => removeMenuItemHandler(item.id)} className="bg-red-500">
                <FaRegTrashAlt className="h-3 w-3" />
            </Button>
        </div>
    )
}

export default CustomListItem