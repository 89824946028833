import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setZoom } from '../../../../lib/features/layout/zoomSlice';

import { motion } from "framer-motion";

import ClientLogoPreview from './../cocktailsPages/clientLogo/'
import BrandLogoPreview from './../cocktailsPages/brandLogo/'
import CoctailMenuItem from './../cocktailsPages/menuItem/coctailMenuItem'

const CatalogPages = ({ state, data }) => {

  const dispatch = useDispatch();

  const page = useSelector((state) => state.page.current)
  const align = useSelector((state) => state.page.align)
  const template = useSelector((state) => state.template.template)

  useEffect(() => {
    dispatch(setZoom(90))
  }, [])

  return (
    <>
      {template.pages[page].map((obj, idx) => (
        <div
          key={state.index}
          className="relative shadow-xl"
          style={{
            width: `${template.currentSize.w}px`,
            height: `${template.currentSize.h}px`,
            backgroundImage: `url(${obj.cover})`,
            position: 'relative'
          }}>
          {page === 0 ? <ClientLogoPreview styles={obj.styles} /> : (
            <>
              <div
                className='absolute mx-auto'
                style={{
                  top: `${obj.styles.itemsTop}`,
                  width: `${obj.styles.itemsTop}`,
                  minWidth: `${obj.styles.itemsMinWidth}`,
                  left: `${obj.styles.itemsLeft}`,
                  right: `${obj.styles.itemsRight}`,
                  textAlign: `${align}`
                }}>
                {template.pages[page][0].items.map((item, idx) => (
                  <motion.main
                    className="main__container"
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
                  >
                    <CoctailMenuItem
                      key={idx}
                      state={item}
                      styles={obj.styles}
                    />
                  </motion.main>
                ))}
              </div>
              <BrandLogoPreview styles={obj.styles} />
              {template.type === 'cocktail' ?
                <div style={{
                  position: 'absolute',
                  bottom: '30px',
                  right: '50px',
                  fontSize: '16px',
                  color: obj.styles.bottomTextColor
                }}>
                  Απολαύστε Υπεύθυνα
                </div>
                : ''}
            </>
          )}
        </div>
      ))}
    </>
  )
}

export default CatalogPages