import React from "react";
import { Input } from "@material-tailwind/react";
import { CiSearch } from "react-icons/ci";

const Search = () => {
    return (
        <div className="w-full md:w-72">
            <Input
                label="Αναζήτηση "
                icon={<CiSearch className="h-5 w-5" />}
            />
        </div>
    )
}

export default React.memo(Search)