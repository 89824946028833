import product_01 from './../assets/products/hotdog.png'
import product_02 from './../assets/products/cocacola.png'

import product_03 from './../assets/products/QSR_SPRITE.png'
import product_04 from './../assets/products/QSR_SPRITE_BURGER.png'

export const products = [
    { id: 1, value: 'cocacola', img: product_02, category: 'drink' },
    { id: 2, value: 'hotdog', img: product_01, category: 'food' },
    { id: 3, value: 'sprite', img: product_03, category: 'drink' },
    { id: 4, value: 'burger', img: product_04, category: 'food' },
]