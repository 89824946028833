import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Chart from './../../../components/Dashboard/chart/'

import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  Chip,
  IconButton,
  Tooltip,
  Button
} from "@material-tailwind/react";

import { FaRegTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

const TABLE_HEAD = ["Κωδικος παραγγελιας", "Καταστήμα", "Υπεύθυνος παραλαβής", "Κωδικος πελάτη", "Ημερομηνια παραγγελιας", "Κατάσταση", ""];

const TABLE_ROWS = [
  {
    _id: "ID12654",
    Store: "Store",
    NameReceivingOfficer: "John Doe",
    CustomerCode: "1234",
    Date: "Wed 3:00pm",
    Status: "complete",
  },
  {
    _id: "ID12654",
    Store: "Store",
    NameReceivingOfficer: "John Doe",
    CustomerCode: "1234",
    Date: "Wed 3:00pm",
    Status: "complete",
  },
  {
    _id: "ID12654",
    Store: "Store",
    NameReceivingOfficer: "John Doe",
    CustomerCode: "1234",
    Date: "Wed 3:00pm",
    Status: "cancel",
  },
  {
    _id: "ID12654",
    Store: "Store",
    NameReceivingOfficer: "John Doe",
    CustomerCode: "1234",
    Date: "Wed 3:00pm",
    Status: "complete",
  },
  {
    _id: "ID12654",
    Store: "Store",
    NameReceivingOfficer: "John Doe",
    CustomerCode: "1234",
    Date: "Wed 3:00pm",
    Status: "pending",
  },
];

const Report = () => {

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 pt-2.5 md:flex-row md:items-center">
          <div className="flex items-center gap-x-5">
            <Typography variant="h5" color="blue-gray">
              Report
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <div className="flex flex-wrap mb-10">
          <div className="w-3/5 p-4">
            <Typography variant="h3" color="blue-gray">
              Παραγγελίες: 10
            </Typography>

            <div className="mt-8 gap-5">
              <div className="flex items-center gap-x-2">
                <div className="w-4 h-4 bg-[#0088FE]"></div>
                <div>- FSR</div>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="w-4 h-4 bg-[#00C49F]"></div>
                <div>- Combo</div>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="w-4 h-4 bg-[#FFBB28]"></div>
                <div>- Catalog</div>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="w-4 h-4 bg-[#FF8042]"></div>
                <div>- CafeBar</div>
              </div>

            </div>
          </div>
          <div className="w-2/5">
            <Chart />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-8 pt-1 md:flex-row md:items-center">
          <div className="flex items-center gap-x-5 px-4 py-5">
            <Typography variant="h6" color="blue-gray">
              Πρόσφατες παραγγελίες
            </Typography>
          </div>
        </div>
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(
              (
                {
                  _id,
                  Store,
                  NameReceivingOfficer,
                  CustomerCode,
                  Date,
                  Status,
                },
                index,
              ) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={_id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {_id}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Typography
                          variant="small"
                          color="blue-gray"
                        >
                          {Store}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {NameReceivingOfficer}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {CustomerCode}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {Date}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={Status}
                          color={
                            Status === "complete"
                              ? "green"
                              : Status === "pending"
                                ? "amber"
                                : "red"
                          }
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className='flex gap-x-4 float-right'>
                        <Tooltip content="Edit User">
                          <Link to="/dashboard/orders/123">
                            <Button variant="outlined" size="sm" className="capitalize">Προβολή</Button>
                          </Link>
                        </Tooltip>
                        <Tooltip content="Delete User">
                          <Button color="red" size="sm" className="capitalize">Ακύρωση</Button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default Report