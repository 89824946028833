import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axios';


// Upload file
export const UploadFile = createAsyncThunk(
    '/upload',
    async (params) => {
        const res = await axios.post('/upload', params)
        return res;
    },
);

const pageSlice = createSlice({
    name: 'Page',
    initialState: {
        current: 0,
        total: 0,
        column: 1,
        logo: '',
        brandLogo: '',
        file: '',
        align: 'center',
        currStep: 1,
        pdf: '',
        pageNav: false,
        loadingFile: '',
        disableZoom: false
    },
    reducers: {
        forward: state => {
            state.current += 1
        },
        back: state => {
            state.current -= 1
        },
        totalPages: (state, action) => {
            state.total = (action.payload)
        },
        oneColumn: state => {
            state.column = 1
        },
        twoColumn: state => {
            state.column = 2
        },
        loadLogo: (state, action) => {
            state.logo = (action.payload)
        },
        removeLogo: (state, action) => {
            state.logo = ''
        },
        loadBrandLogo: (state, action) => {
            state.brandLogo = (action.payload)
        },
        removeBrandLogo: (state, action) => {
            state.brandLogo = ''
        },
        loadFile: (state, action) => {
            state.file = (action.payload)
        },
        removeFile: (state, action) => {
            state.file = ''
        },
        alignLeft: (state) => {
            state.align = 'left'
        },
        alignCenter: (state) => {
            state.align = 'center'
        },
        alignRight: (state) => {
            state.align = 'right'
        },

        currStepForward: state => {
            state.currStep += 1
        },
        currStepBackward: state => {
            state.currStep -= 1
        },
        currStepSet: (state, action) => {
            state.currStep = action.payload
        },

        removePDFFile: (state) => {
            state.pdf = ''
        },

        pageNav: (state, action) => {
            state.pageNav = (action.payload)
        },

        changeStatus: (state) => {
            state.loadingFile = ''
        },

        disableZoom: (state, action) => {
            state.disableZoom = action.payload
        }
    },
    extraReducers: (builder) => {
        builder

            // Upload file
            .addCase(UploadFile.pending, (state) => {
                state.loadingFile = 'loading';
                state.pdf = null;
            })
            .addCase(UploadFile.fulfilled, (state, action) => {
                state.loadingFile = 'loaded';
                state.pdf = action.payload;
            })
            .addCase(UploadFile.rejected, (state, action) => {
                state.loadingFile = 'loading';
                state.tpdf = null;
            })
    }
})

export const {
    forward,
    back,
    totalPages,
    oneColumn,
    twoColumn,
    loadLogo,
    removeLogo,
    loadBrandLogo,
    removeBrandLogo,
    loadFile,
    removeFile,
    alignLeft,
    alignCenter,
    alignRight,
    currStepForward,
    currStepBackward,
    currStepSet,
    removePDFFile,
    pageNav,
    changeStatus,
    disableZoom
} = pageSlice.actions

export const pageReducer = pageSlice.reducer
