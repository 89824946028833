import React, { useEffect, useState } from 'react'
import { Select, Option } from "@material-tailwind/react";
import { useDispatch } from 'react-redux'

import {
    addTemplateMenuComboPrice,
    addTemplateMenuComboOfferPrice,
    addTemplateMenuComboDescription
} from './../../../../lib/features/template/templateSlice';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PriceOffer = () => {

    const dispatch = useDispatch()

    const [value, setValue] = useState('XXL HOT DOG <br/> + COCA-COLA 500ml');

    const onHandleChangePrice = (e) => {
        dispatch(addTemplateMenuComboPrice({ price: e.target.value }))
    }

    const onHandleChangeOfferPrice = (e) => {
        dispatch(addTemplateMenuComboOfferPrice({ priceOffer: e.target.value }))
    }

    const onHandleChangeDescription = (e) => {
        dispatch(addTemplateMenuComboDescription({ description: e.target.value.toString('html') }))
    }

    useEffect(() => {
        dispatch(addTemplateMenuComboDescription({ description: value }))
    }, [value])

    return (
        <div>
            <div className="text-2xl font-semibold py-2">Συμπληρώστε τιμές και περιγραφή</div>
            <div className="mt-5">
                <div
                    className="max-w-screen-lg mt-8 mb-2 w-100 sm:w-100">
                    <div className="flex flex-col gap-6 mb-1">

                        <div className="flex justify-between gap-x-4 mb-1">
                            <div className="w-full">
                                <h6
                                    className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                    Τιμή
                                </h6>
                                <div className="relative h-11 w-full min-w-[200px]">
                                    <input
                                        onChange={onHandleChangePrice}
                                        name="price"
                                        variant="standard"
                                        placeholder="00.00 €"
                                        className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                    <label
                                        className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                                </div>
                            </div>
                            <div className="w-full">
                                <h6
                                    className="block mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                                    Τιμή προσφοράς
                                </h6>
                                <div className="relative h-11 w-full min-w-[200px]">
                                    <input
                                        onChange={onHandleChangeOfferPrice}
                                        name="offerPrice"
                                        placeholder="00.00 €"
                                        className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                                    <label
                                        className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all before:content-none after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all after:content-none peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label>
                                </div>
                            </div>
                        </div>
                        <h6
                            className="block -mb-3 font-sans text-base antialiased font-semibold leading-none tracking-normal text-blue-gray-900">
                            Περιγραφή
                        </h6>
                        <div className="w-100">
                            <ReactQuill 
                            style={{ height: '150px', marginBottom: '30px'}}
                            theme="snow" 
                            value={value} 
                            onChange={setValue} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(PriceOffer)